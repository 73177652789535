import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
// import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { LangType } from 'pancake-uikit'
import { selectedLanguageCode } from 'constants/localisation'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { DEFAULT_CHAINID, DEFAULT_TOKEN_LIST_URL } from 'constants/lists'
import { selectList } from 'state/lists/actions'
import { usePollBlockNumber } from 'state/block/hooks'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import Migration from './Migration'

import Menu from '../components/Menu'
import useGetDocumentTitlePrice from '../hooks/useGetDocumentTitlePrice'
import Farms from './Farms'
import Pools from './Pools'
import BurnFund from './BurnFund'
import Home from './Home'
import CreamPresale, { CreamPresaleList } from './CreamPresale'
import Deposit from './CreamPresale/deposit'
import { GamePage } from './Game'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  margin-bottom: 64px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`

const CACHE_KEY = 'pancakeSwapLanguage'

export default function App() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])

  usePollBlockNumber()
  // const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  // const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  // const fileId = 6
  // const credentials: Credentials = {
  //   token: apiKey,
  // }

  // const stringTranslationsApi = new StringTranslations(credentials)

  // const [hasSeenModal, setHasSeenModal] = useState(false)
  // const [onPresentUseV2ExchangeModal] = useModal(<UseV2ExchangeModal />)

  // useEffect(() => {
  //   const showModal = () => {
  //     // onPresentUseV2ExchangeModal()
  //     setHasSeenModal(true)
  //   }
  //   if (!hasSeenModal) {
  //     showModal()
  //   }
  // }, [onPresentUseV2ExchangeModal, hasSeenModal])

  useEffect(() => {
    if (!chainId) return
    console.log(DEFAULT_CHAINID[chainId])

    dispatch(selectList(DEFAULT_CHAINID[chainId] || DEFAULT_TOKEN_LIST_URL))
  }, [chainId, dispatch])

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem(CACHE_KEY)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    setTranslations(selectedLanguageCode[selectedLanguage.code])

    // stringTranslationsApi
    //   .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
    //   .then((translationApiResponse) => {
    //     if (translationApiResponse.data.length < 1) {
    //       setTranslations(['error'])
    //     } else {
    //       setTranslations(translationApiResponse.data)
    //     }
    //   })
    //   .then(() => setTranslatedLanguage(selectedLanguage))
    //   .catch((error) => {
    //     setTranslations(['error'])
    //     console.error(error)
    //   })
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const handleLanguageSelect = (langObject: LangType) => {
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
  }

  useGetDocumentTitlePrice()

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{
              selectedLanguage,
              setSelectedLanguage: handleLanguageSelect,
              translatedLanguage,
              setTranslatedLanguage,
            }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path="/" component={Home} />

                      {/* <Route exact strict path="/farms" component={Farms} /> */}
                      {/* <Route exact strict path="/pools" component={Pools} /> */}
                      <Route exact strict path="/game/**" component={GamePage} />
                      <Route exact strict path="/game" component={GamePage} />
                      <Route exact strict path="/presale/**" component={CreamPresale} />
                      <Route exact strict path="/presale" component={CreamPresaleList} />
                      {/* <Route exact strict path="/deposit" component={Deposit} /> */}
                      <Route exact strict path="/burnFund" component={BurnFund} />
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact path="/migrate" component={Migration} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

                      {/* Redirection: These old routes are still used in the code base */}
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                    </Switch>
                  </Web3ReactManager>
                </BodyWrapper>
              </Menu>
              {/* <VersionBar /> */}
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
