import { ethers } from 'ethers'
import { ChainId } from 'pancake-sdk'

export const simpleRpcProviderList = {
  [ChainId.MAINNET]: new ethers.providers.StaticJsonRpcProvider('https://binance.llamarpc.com'),
  [ChainId.OKX]: new ethers.providers.StaticJsonRpcProvider('https://exchainrpc.okex.org'),
}

export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider('https://binance.llamarpc.com')
// export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider('https://exchainrpc.okex.org')

// https://exchainrpc.okex.org

export default null
