import { useCallback, useState } from 'react'
import { Wallet, ethers, utils } from 'ethers'

import { useAppDispatch, useAppSelector } from 'state'
import { match } from 'utils/match'
import { estimateGas } from 'utils/calls/estimateGas'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { CurrencyAmount, TokenAmount } from 'pancake-sdk'
import { useCreamPresaleContract } from 'hooks/useContractV2'
import { fetchPresaleDataAsync, fetchPresaleUserDataAsync } from 'state/presale'
import { useGasPrice } from 'state/user/hooks/h-index'
import { useTransactionAdder } from 'state/transactions/hooks'
import { splitSignature } from 'ethers/lib/utils'
import { message } from 'antd'

export const usePresaleAll = ({ address, toeknAmount }: { address: string; toeknAmount: CurrencyAmount }) => {
  const { reqLoader: reqLoaderDeposit, depositCallback } = useDeposit({ address, toeknAmount })
  const { reqLoader: reqLoaderClaim, claimCallback } = useClaim({ address })
  const { reqLoader: reqLoaderClaimEnd, claimEndCallback } = useClaimEnd({ address })
  const { reqLoader: reqLoaderClaimLp, claimLpCallback } = useClaimLp({ address })

  return {
    reqLoaderDeposit,
    reqLoaderClaim,
    reqLoaderClaimEnd,
    reqLoaderClaimLp,
    depositCallback,
    claimCallback,
    claimEndCallback,
    claimLpCallback,
  }
}

export const useDeposit = ({ address, toeknAmount }: { address: string; toeknAmount: CurrencyAmount }) => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const presaleContract = useCreamPresaleContract(address)

  const token = toeknAmount instanceof TokenAmount ? toeknAmount.token : undefined

  console.log(`token: ${token}`)
  const depositCallback = useCallback(async () => {
    try {
      setReqLoader(true)
      const gasLimit = await estimateGas(
        presaleContract,
        'deposit',
        [token ? toeknAmount.raw.toString() : '0', { value: !token ? toeknAmount.raw.toString() : '0' }],
        1000
      )
      const tx = await presaleContract.deposit(token ? toeknAmount.raw.toString() : '0', {
        value: !token ? toeknAmount.raw.toString() : '0',
        gasLimit,
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchPresaleDataAsync({ chainId, account, presaleAddress: address }))
        dispatch(fetchPresaleUserDataAsync({ chainId, account, presaleAddress: address }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      console.log(e)
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, token, presaleContract, toeknAmount, address, chainId, account])

  return { reqLoader, depositCallback }
}

export const useClaim = ({ address }: { address: string }) => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const presaleContract = useCreamPresaleContract(address)

  const claimCallback = useCallback(async () => {
    try {
      setReqLoader(true)
      const gasLimit = await estimateGas(presaleContract, 'claim', [], 1000)
      const tx = await presaleContract.claim({ gasLimit })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchPresaleDataAsync({ chainId, account, presaleAddress: address }))
        dispatch(fetchPresaleUserDataAsync({ chainId, account, presaleAddress: address }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      console.log(e)
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, presaleContract, chainId, address, account])

  return { reqLoader, claimCallback }
}

export const useClaimEnd = ({ address }: { address: string }) => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const presaleContract = useCreamPresaleContract(address)

  const claimEndCallback = useCallback(async () => {
    try {
      setReqLoader(true)
      const gasLimit = await estimateGas(presaleContract, 'claimEnd', [], 1000)
      const tx = await presaleContract.claimEnd({ gasLimit })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchPresaleDataAsync({ chainId, account, presaleAddress: address }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      console.log(e)
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, presaleContract, chainId, address, account])

  return { reqLoader, claimEndCallback }
}

export const useClaimLp = ({ address }: { address: string }) => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const presaleContract = useCreamPresaleContract(address)

  const claimLpCallback = useCallback(async () => {
    try {
      setReqLoader(true)
      const gasLimit = await estimateGas(presaleContract, 'claimLp', [], 1000)
      const tx = await presaleContract.claimLp({ gasLimit })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchPresaleDataAsync({ chainId, account, presaleAddress: address }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      console.log(e)
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, presaleContract, chainId, address, account])

  return { reqLoader, claimLpCallback }
}

export const useBatchAppovetToken = () => {
  const { chainId, account } = useActiveWeb3React()
  const [reqLoading, setReqLoading] = useState(false)
  const gasPrice = useGasPrice()
  const addTransaction = useTransactionAdder()

  const batchApprove = useCallback(
    async (wallets: Wallet[], txns: any[], spender: string) => {
      try {
        setReqLoading(true)

        const estimatedCalls: any[] = await Promise.all(wallets.map((wallet, index) => wallet.estimateGas(txns[index])))
        // await provider
        //   .send('personal_sign', [`签名批量授权: \n spender 账户: ${spender}`, account])
        //   .then(splitSignature)
        const responseList = await Promise.all(
          wallets.map((wallet, index) =>
            wallet.sendTransaction({ ...txns[index], gasLimit: estimatedCalls[index], gasPrice })
          )
        )
        // responseList.map((response) => {
        //   addTransaction(response, {
        //     summary: 'withRecipient',
        //   })
        //   return null
        // })
        // 此处的 receiptList 是所有交易的 receipt 列表，可以用于后续处理
        const receiptList = await Promise.all(responseList.map((response) => response.wait()))
        const allTransactionsSucceeded = receiptList.every((receipt) => receipt.status === 1)
        if (allTransactionsSucceeded) {
          // 所有交易都成功，执行其他逻辑
          // dispatch(fetchAccountUserDataAsync({ chainId, account }))
        }
        setReqLoading(false)
      } catch (error: any) {
        setReqLoading(false)
        message.error(match(error.toString()) || error?.message)
      }
    },
    [gasPrice]
  )

  return { reqLoading, batchApprove }
}
