import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M16 6L8 6C6.40869 6 4.88257 6.63214 3.75732 7.75736C2.63208 8.88258 2 10.4087 2 12C2 13.5913 2.63208 15.1174 3.75732 16.2426C4.88257 17.3679 6.40869 18 8 18L16 18C17.5913 18 19.1174 17.3679 20.2427 16.2426C21.3679 15.1174 22 13.5913 22 12C22 10.4087 21.3679 8.88258 20.2427 7.75736C19.1174 6.63214 17.5913 6 16 6ZM11 13L9 13L9 15L7 15L7 13L5 13L5 11L7 11L7 9L9 9L9 11L11 11L11 13ZM14.5 15C14.1022 15 13.7207 14.842 13.4393 14.5607C13.1581 14.2794 13 13.8978 13 13.5C13 13.1022 13.1581 12.7206 13.4393 12.4393C13.7207 12.158 14.1022 12 14.5 12C14.8978 12 15.2793 12.158 15.5607 12.4393C15.8419 12.7206 16 13.1022 16 13.5C16 13.8978 15.8419 14.2794 15.5607 14.5607C15.2793 14.842 14.8978 15 14.5 15ZM17.5 12C17.1022 12 16.7207 11.842 16.4393 11.5607C16.1581 11.2794 16 10.8978 16 10.5C16 10.1022 16.1581 9.72064 16.4393 9.43934C16.7207 9.15804 17.1022 9 17.5 9C17.8978 9 18.2793 9.15804 18.5607 9.43934C18.8419 9.72064 19 10.1022 19 10.5C19 10.8978 18.8419 11.2794 18.5607 11.5607C18.2793 11.842 17.8978 12 17.5 12Z" />
    </Svg>
  )
}

export default Icon
