import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PresaleState, UserInfo } from 'state/types'
import { fetchCreamPresaleListState, fetchPresaleState, fetchPresaleUserState } from './fetchPresale'

/* eslint-disable */
export interface SerializedPresaleState {
  presaleList: PresaleState []
  userInfo?: { [address: string]: UserInfo }
  mainPresaleState?: PresaleState
  mainUserState?: UserInfo
}

const initialState: SerializedPresaleState = {
  presaleList: [] ,
  userInfo: undefined,
  mainPresaleState: undefined,
  mainUserState: undefined,
}

export const fetchPresaleDataAsync = createAsyncThunk<
  { mainPresale: PresaleState },
  { account: string; chainId: number; presaleAddress: string },
  {
    state: any
  }
>('farms/fetchPresaleDataAsync', async ({ account, chainId, presaleAddress }) => {
  const presaleState = await fetchPresaleState(chainId, presaleAddress)
  return { mainPresale: presaleState }
})

export const fetchPresaleUserDataAsync = createAsyncThunk<
  { mainUser: UserInfo },
  { account: string; chainId: number; presaleAddress: string },
  {
    state: any
  }
>('farms/fetchPresaleUserDataAsync', async ({ account, chainId, presaleAddress }) => {
  const userInfo = await fetchPresaleUserState(chainId, presaleAddress, account)
  return { mainUser: userInfo }
})

export const fetchCreamPresaleListAsync = createAsyncThunk<
  { presaleList: PresaleState []  },
  { chainId: number },
  {
    state: any
  }
>('farms/fetchCreamPresaleListAsync', async ({ chainId }) => {
  const presaleList =  await fetchCreamPresaleListState(chainId)
  console.log(presaleList)
  return {presaleList}
})

export const accountSlice = createSlice({
  name: 'Presale',
  initialState,
  reducers: {
    // resettingAccounts: (state, action) => {
    // },
  },
  extraReducers: (builder) => {
    // Init farm data
    builder.addCase(fetchPresaleDataAsync.fulfilled, (state, action) => {
      console.log(action.payload)
      state.mainPresaleState = { ...action.payload.mainPresale }
    })
    builder.addCase(fetchPresaleUserDataAsync.fulfilled, (state, action) => {
      state.mainUserState = { ...action.payload.mainUser }
    })
    builder.addCase(fetchCreamPresaleListAsync.fulfilled, (state, action) => {
      state.presaleList = action.payload.presaleList
    })
  },
})

// 导出 resettingAccounts 函数
// export const { resettingAccounts } = accountSlice.actions
export default accountSlice.reducer
