import { ChainId } from 'pancake-sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address): string => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID as string, 10)
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}

export const getMulticallAddress = (chainId?: ChainId) => {
  return chainId ? addresses.multicall[chainId] : getAddress(addresses.multicall)
}

export const getIfoPoolAddress = () => {
  return getAddress(addresses.ifoPool)
}

export const getCreamFundAddress = (chainId?: ChainId) => {
  return chainId ? addresses.creamFund[chainId] : getAddress(addresses.creamFund)
}

export const getCreamTokenAddress = (chainId?: ChainId) => {
  return chainId ? addresses.creamToken[chainId] : getAddress(addresses.creamToken)
}

export const getCreamPresaleListAddress = (chainId?: ChainId) => {
  return chainId ? addresses.CreamPresaleList[chainId] : getAddress(addresses.CreamPresaleList)
}


export const getCreamGameAddress = (chainId?: ChainId) => {
  return chainId ? addresses.creamGame[chainId] : getAddress(addresses.creamGame)
}

export const getFeeOnTransferDetectorAddress = (chainId?: ChainId) => {
  return addresses.feeOnTransferDetector[chainId] || getAddress(addresses.creamGame)
}


export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}
