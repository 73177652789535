import React, { Suspense, useEffect, useState, useMemo, useCallback } from 'react'
import Container from 'components/Container'
import PageHeader from 'components/PageHeader'
import { Wrapper } from 'components/TransactionConfirmationModal/helpers'
import useI18n from 'hooks/useI18n'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from 'pages/AppBody'
import { Button, CardBody } from 'pancake-uikit'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { ChainId, CurrencyAmount, ETHER, Token, TokenAmount } from 'pancake-sdk'
import styled from 'styled-components'
import { Avatar } from 'antd'
import { useTotalSupply } from 'data/TotalSupply'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getCreamFundAddress, getCreamTokenAddress, getMulticallAddress } from 'utils/addressHelpers'
import multicall, { multicallV3 } from 'utils/multicall'
import { ERC20_ABI } from 'constants/abis/erc20'
import { MULTICALL_ABI } from 'constants/multicall'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { BigNumber, ethers, utils } from 'ethers'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { Dots } from 'components/swap/styleds'
import { RowBetween } from 'components/Row'
import { useCreamFundContract } from 'hooks/useContractV2'

const CreamBurn = styled.div`
  height: 200px;
  display: flex;
  > div {
    flex: 1;
    border-radius: 16px;
    background-color: #f9f7f8;
    box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
    padding: 12px 22px;
    color: #000;

    h1 {
      margin-bottom: 30px;
    }
    .info {
      color: #00000088;
      text-align: center;
      p {
        margin: 20px 0px;
      }
    }
  }
`

const BurnAmount = styled.div`
  text-align: center;
`

export const fetchTokenTotalSupply = async (token: Token): Promise<TokenAmount> => {
  const calls = [
    {
      address: getCreamTokenAddress(token.chainId),
      name: 'totalSupply',
      params: [],
    },
  ]
  const [totalSupply] = await multicallV3(token.chainId, ERC20_ABI, calls)
  return token && new TokenAmount(token, totalSupply[0].toString())
}

export const fetchTokenETHBalances = async (chainId: ChainId, account: string): Promise<CurrencyAmount> => {
  const calls = [
    {
      address: getMulticallAddress(chainId),
      name: 'getEthBalance',
      params: [account],
    },
    {
      address: getMulticallAddress(chainId),
      name: 'getCurrentBlockTimestamp',
      params: [],
    },
  ]
  const [balance, time] = await multicallV3(chainId, MULTICALL_ABI, calls)
  console.log(`balance: ${time}`)
  return CurrencyAmount.ether(balance[0].toString())
}

const BurnFund = () => {
  const { chainId, account } = useActiveWeb3React()
  const TranslateString = useI18n()

  const creamFundContract = useCreamFundContract()
  const [typedValue, setTypedValue] = useState('')
  const [[fundBalance, creamTotalSupply], setInitData] = useState<[CurrencyAmount, TokenAmount]>([null, null])

  const cream = useMemo(
    () => new Token(chainId, getCreamTokenAddress(chainId), 18, 'CREAM', 'Binance-Peg Ethereum Token'),
    [chainId]
  )
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, cream ?? undefined)

  const rewardBNB = useMemo(
    () =>
      typedValue &&
      fundBalance &&
      creamTotalSupply &&
      CurrencyAmount.ether(
        BigNumber.from(fundBalance.raw.toString())
          .mul(utils.parseEther(typedValue))
          .div(creamTotalSupply.raw.toString())
          .toString()
      ),
    [typedValue, fundBalance, creamTotalSupply]
  )

  /* eslint-disable */
  const error = useMemo(() => {
    if (BigNumber.from(selectedCurrencyBalance?.raw.toString() || '0').lt(utils.parseEther(typedValue || '0'))) {
      return `Insufficient cream balance`
    }
    if (Number(fundBalance?.toSignificant() || 0) === 0) {
      return `fund 0 bnb`
    }
    return ''
  }, [selectedCurrencyBalance, typedValue, fundBalance])

  const [approvalA, approveACallback] = useApproveCallback(
    new TokenAmount(cream, utils.parseEther(typedValue || '0').toString()),
    getCreamFundAddress(chainId)
  )

  const [reqCream, setReqCream] = useState(false)
  const creamFundHook = useCallback(async () => {
    try {
      setReqCream(true)
      const tx = await creamFundContract.burnCream(utils.parseEther(typedValue))
      const resp = await tx.wait()
      if (resp.status) {
        console.log(`执行成功了`)
      }
      setReqCream(false)
      setTypedValue('')
    } catch (e) {
      setReqCream(false)
      console.log(e)
    }
  }, [creamFundContract, typedValue])

  useEffect(() => {
    const initData = async () => {
      if (!chainId) return

      const _fundBalance = await fetchTokenETHBalances(chainId, getCreamFundAddress(chainId))
      const _creamTotalSupply = await fetchTokenTotalSupply(cream)

      setInitData([_fundBalance, _creamTotalSupply])
    }

    initData()
  }, [chainId, account, cream, reqCream])

  return (
    <Container>
      {/* <CardNav /> */}
      <AppBody>
        <Wrapper id="cream_burn">
          <PageHeader
            title={TranslateString(8, 'CREAM Burn')}
            description={TranslateString(1192, 'Destroy cream to obtain BNB')}
            show
          />
          <CardBody>
            <AutoColumn gap="md">
              <CreamBurn>
                <div className="creamFund">
                  <h1>{TranslateString(8, 'Fund balance')}</h1>
                  <div className="info">
                    <Avatar size={50} src="images/coins/bnb.png" />
                    <p>{fundBalance?.toSignificant() || '0'}</p>
                  </div>
                </div>
                <p style={{ width: '20px' }} />
                <div className="creamToken">
                  <h1>{TranslateString(8, 'Total Tokens')}</h1>
                  <div className="info">
                    <Avatar size={50} src="favicon.png" />
                    <p>{creamTotalSupply?.toSignificant() || '0'}</p>
                  </div>
                </div>
              </CreamBurn>
              <CurrencyInputPanel
                label="Burn"
                value={typedValue}
                showMaxButton={Boolean('true')}
                currency={cream}
                onUserInput={setTypedValue}
                onMax={() => {
                  setTypedValue(selectedCurrencyBalance?.toExact())
                }}
                // onCurrencySelect={() => void}
                otherCurrency={null}
                show
                id="swap-currency-input"
              />

              {typedValue && (
                <BurnAmount>
                  {' '}
                  {typedValue === selectedCurrencyBalance?.toExact() ? Number(typedValue).toFixed(3) : typedValue} cream
                  = {rewardBNB?.toSignificant() || '0'} bnb
                </BurnAmount>
              )}

              {!account ? (
                <ConnectWalletButton width="100%" />
              ) : (
                <AutoColumn gap="md">
                  {approvalA !== ApprovalState.APPROVED ? (
                    <RowBetween>
                      <Button
                        onClick={() => {
                          try {
                            approveACallback()
                          } catch (e) {
                            console.log(e)
                          }
                        }}
                        disabled={approvalA === ApprovalState.PENDING}
                        style={{ width: '100%' }}
                      >
                        {approvalA === ApprovalState.PENDING ? (
                          <Dots>Approving {cream?.symbol}</Dots>
                        ) : (
                          `Approve ${cream?.symbol}`
                        )}
                      </Button>
                    </RowBetween>
                  ) : (
                    <Button
                      style={{ borderRadius: '30px' }}
                      width="100%"
                      disabled={reqCream || !!error}
                      variant={reqCream ? 'danger' : 'primary'}
                      onClick={creamFundHook}
                    >
                      {reqCream ? (
                        <Dots>{TranslateString(1164, error || 'Burn Cream')}</Dots>
                      ) : (
                        TranslateString(1164, error || 'Burn Cream')
                      )}
                    </Button>
                  )}
                </AutoColumn>
              )}
            </AutoColumn>
          </CardBody>
        </Wrapper>
      </AppBody>
    </Container>
  )
}

export default BurnFund
