import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GameUsserInfo, PresaleState, UserInfo } from 'state/types'
import { fetchGameDataState, fetchGameUserDataState, fetchHierarchyListDataState } from './fetchGame'

/* eslint-disable */
export interface SerializedPresaleState {
  rank?: number
  code?: number
  wholeNetwork?: number
  totalShares?: string
  hierarchyList?: GameUsserInfo[][]
  userInfo?: GameUsserInfo
  shareIncome?: string
  codeRef?: number
}

const initialState: SerializedPresaleState = {
  codeRef: undefined,
  hierarchyList: undefined,
}

export const fetchGameUserDataAsync = createAsyncThunk<
  { userInfo: GameUsserInfo },
  { account: string; chainId: number },
  {
    state: any
  }
>('farms/fetchGameUserDataAsync', async ({ account, chainId }) => {
  const userInfo = await fetchGameUserDataState(chainId, account)
  return { userInfo }
})

export const fetchGameDataAsync = createAsyncThunk<
  { rank: number; code: number; wholeNetwork: number; totalShares: string },
  { chainId: number },
  {
    state: any
  }
>('farms/fetchGameDataAsync', async ({ chainId }) => {
  const gameDataState = await fetchGameDataState(chainId)
  return { ...gameDataState }
})

export const fetchListDataAsync = createAsyncThunk<
  { hierarchyList: any },
  { account: string; chainId: number },
  {
    state: any
  }
>('farms/fetchListDataAsync', async ({ account, chainId }) => {
  const hierarchyList = await fetchHierarchyListDataState(chainId, account)
  return { hierarchyList }
})

export const accountSlice = createSlice({
  name: 'Game',
  initialState,
  reducers: {
    setCode: (state, action) => {
      state.codeRef = action.payload
    },
  },
  extraReducers: (builder) => {
    // Init farm data
    builder.addCase(fetchGameUserDataAsync.fulfilled, (state, action) => {
      state.userInfo = action.payload.userInfo
    }),
      builder.addCase(fetchGameDataAsync.fulfilled, (state, action) => {
        state.rank = action.payload.rank
        state.wholeNetwork = action.payload.wholeNetwork
        state.totalShares = action.payload.totalShares
        state.code = action.payload.code
      }),
      builder.addCase(fetchListDataAsync.fulfilled, (state, action) => {
        state.hierarchyList = [...action.payload.hierarchyList]
      })
  },
})

// 导出 resettingAccounts 函数
export const { setCode } = accountSlice.actions
export default accountSlice.reducer
