import styled, { DefaultTheme } from 'styled-components'
import { Variant, variants } from '../Button/types'

type StyledButtonMenuProps = {
  variant: Variant
  theme: DefaultTheme
}

const getBackgroundColor = ({ theme, variant }: StyledButtonMenuProps) => {
  return theme.colors[variant === variants.SUBTLE ? 'input' : 'tertiary']
}

// linear-gradient(90deg, #6848d2, #b2589a) !important;
const StyledButtonMenu = styled.div<{ variant: Variant }>`
  color: #fcc022;
  background-color: #ec8bc5;
  border-radius: 20px;
  display: inline-flex;
  height: 40px;
  line-height: 40px;

  a {
    line-height: 40px !important;
  }

  & > button + button,
  & > a + a {
    line-height: 40px !important;
    height: 40px;
    padding: 0px 20px;
    color: #fff;
  }

  & > a {
    line-height: 40px !important;
    height: 40px;
    padding: 0px 20px;
    color: #fff;
  }

  .leWTuW {
    border-radius: 20px;
    background: #6f66f6;
    /* background: linear-gradient(90deg, #6848d2, #b2589a) !important; */
    box-shadow: none;
  }
  .kUyxNz {
    border-radius: 20px;
    background: #6f66f6;
    /* background: linear-gradient(90deg, #6848d2, #b2589a) !important; */
    box-shadow: none;
    color: #fff;
  }
  .dnrNNQ {
    border-radius: 20px;
    background: #6f66f6;
    /* background: linear-gradient(90deg, #6848d2, #b2589a) !important; */
    box-shadow: none;
    color: #fff;
  }
  width: 100%;
  > button {
    height: 100%;
    width: 50%;
  }
  .kkHDKd {
    border-radius: 20px;
    background: #ec8bc5;
    box-shadow: none;
    color: #fff;
  }
`

export default StyledButtonMenu
