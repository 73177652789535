import styled from 'styled-components'
import { Input, Progress, Col, Row, Statistic, Radio, RadioChangeEvent } from 'antd'
import { useAppDispatch, useAppSelector } from 'state'
import React, { useEffect, useState, useMemo } from 'react'
import useI18n from 'hooks/useI18n'
import Loader from 'components/Loader'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { CurrencyAmount, Token, TokenAmount } from 'pancake-sdk'
import { getCreamPresaleListAddress } from 'utils/addressHelpers'
import { fetchCreamPresaleListAsync, fetchPresaleDataAsync, fetchPresaleUserDataAsync } from 'state/presale'
import { useCurrency } from 'hooks/Tokens'
import { unwrappedToken, wrappedCurrency } from 'utils/wrappedCurrency'
import { MainPresaleState, MainUserInfo, PresaleState } from 'state/types'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { BigNumber, utils } from 'ethers'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import Container from 'components/Container'
import { Dots } from 'components/swap/styleds'
import { Button, ButtonMenuItem } from 'pancake-uikit'
import { useParams } from 'react-router-dom'
import CountDown from './components/CountDown'
import { usePresaleAll } from './hooks/hooks'
import CountDownV2 from './components/CountDownV2'

const IDOHTML = styled.div`
  margin: 0px auto;
  /* background-color: rgb(77, 84, 255); */
  padding: 0px 20px;

  @media screen and (min-width: 360px) {
    width: 90%;
    padding: 0px 0px;
    margin-top: 20px;
    /* height: 100vh; */
  }
  @media screen and (min-width: 576px) {
    width: 100%;
    padding: 0px 20px;
    margin-top: 0px;
  }
  .main {
    height: auto;
    /* background: #fff; */
    @media screen and (min-width: 360px) {
      width: 100%;
      position: none;
      top: 0%;
      left: 0%;
      transform: none;
    }
    @media screen and (min-width: 576px) {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 30%);

      padding: 0px 20px;
      border-radius: 10px;
    }

    @media screen and (min-width: 1110px) {
      width: 1162px;
    }
    .ant-btn2 {
      opacity: 0.5 !important;
      cursor: not-allowed;
    }
    > .presaleInfo {
      background: rgb(255, 255, 255);
      height: auto;
      width: auto;
      overflow: hidden;
    }

    > .presaleTop {
      margin-bottom: 20px;
      > div {
        padding: 32px;
        background: #fff;
        flex: 1;
      }

      @media screen and (min-width: 576px) {
        display: block;

        > div {
          display: block;
        }
      }

      @media screen and (min-width: 1110px) {
        display: flex;
        > div {
          flex: 1;
        }
      }
      .title_time {
        > p {
          font-size: 13px;
          text-align: center;
          background: #f9f7f8;
          padding: 5px 0px;
        }
        > div {
          margin-top: 10px !important;
          display: block;
          width: 220px;
          margin: 0px auto;
        }
      }
      .liquidity {
        > div {
          flex: 1;
        }
        margin-top: 20px;
        @media screen and (min-width: 576px) {
          margin-top: 20px;
          display: block;
        }

        @media screen and (min-width: 1110px) {
          margin-top: 0px;
          display: flex;
        }

        .lp {
          @media screen and (min-width: 576px) {
            margin-top: 20px;
          }

          @media screen and (min-width: 1110px) {
            margin-top: 0px;
          }
        }
      }
    }
  }
  /* span {
    font-family: fangsong !important;
  }
  div {
    font-family: fangsong !important;
  }
  p {
    font-family: fangsong !important;
  } */
  .ido {
    color: #000;
    border-radius: 20px;
    /* height: 451px; */
    /* float: left; */
    @media screen and (min-width: 360px) {
      display: block;
      margin: 0px auto;
      float: none;
      padding: 24px;
      width: 100%;
    }
    @media screen and (min-width: 576px) {
      /* margin: 0px 20px; */
      display: inline-block;
      float: left;
      padding: 32px;
      width: calc(100% - 340px);
    }
    @media screen and (min-width: 1110px) {
      width: 730px;
    }
    .title {
      font-weight: 600;
      font-size: 20px;
    }

    .ant-progress {
      margin: 0px;
    }
    .pa {
      height: 1px;
      width: 100%;
      background-color: #fffad0;
      margin: 10px 0px 30px 0px;
    }
    .pa1 {
      height: 1px;
      width: 100%;
      background-color: #fffad0;
      margin: -10px 0px 10px 0px;
    }
    .progress {
      padding: 20px 0px;
      .s1 {
        font-size: 13px;
        span {
          float: right;
        }
      }
      .edu {
        font-size: 12px;

        color: rgb(77, 84, 255);
        span:nth-child(2) {
          float: right;
        }
      }
    }

    .purchase {
      .balance {
        font-size: 14px;
        line-height: 18px;

        .ant-input-focused {
          border: 1px solid rgb(77, 84, 255);
        }
      }

      .t {
        margin: 10px 0px;

        span {
          float: right;
        }
      }
    }
    .edu {
      font-size: 10px;
      color: red;
    }
  }
  .claimable {
    margin-top: 30px;
    font-size: 12px;
    line-height: 18px;
    > div > span {
      float: right;
    }
  }
  .claimable1 {
    margin-top: 8px;
  }

  .ido2 {
    display: inline-block;
    height: auto;
    margin: 0px auto;
    @media screen and (min-width: 360px) {
      display: block;
      margin: 0px auto;
      float: none;
      width: 100%;
      padding: 24px;
    }
    @media screen and (min-width: 576px) {
      display: inline-block;
      float: left;
      width: 390px;
      padding: 32px;
    }
    color: #000;
    .title {
      font-weight: 600;
      font-size: 20px;
    }
    .tp {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .sale {
      margin: 24px 0px;
    }
    /* position: relative; */
    .info {
      width: 100%;
      /* position: absolute; */
      /* bottom: 0px; */
      font-size: 14px;
      line-height: 34px;
      border-radius: 20px;
      /* top: 32px; */

      /* padding: 32px; */
      color: #00000088;
      p {
      }
      p > span {
        float: right;
        color: #6f66f6;
      }
    }

    .white {
      position: absolute;
      top: 0px;
      width: 100%;
      background-color: #fff;
      border-radius: 20px;
      padding: 32px;
      text-align: center;
      margin-bottom: 36px;
    }
  }
`

const IdoProgress = styled(Progress)`
  .ant-progress-inner {
    background-color: #f9f7f8;
  }
  .ant-progress-bg {
    height: 12px;
  }
`

const IdoInput = styled(Input)`
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
  border: 1px solid #f9f7f8 !important;
  height: 80px;
  position: relative;

  background: #f9f7f8;
  :hover {
    border: 1px solid rgb(77, 84, 255) !important;
    box-shadow: 0 0 0 2px rgb(236 230 194 / 10%);
  }
  .ant-input-suffix {
    color: rgb(77, 84, 255);
    font-weight: 600;
  }
  span > div {
    cursor: pointer;
  }
  .ant-input {
    background: #f9f7f8;
    height: 38px;
    border: 1px solid #f9f7f8;
    border-radius: 0px !important;
    border-right: none;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-top: 20px;
    color: #00000088;
    ::placeholder {
      color: #00000088;
    }
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%); */
    /* :hover {
      border: 1px solid rgb(77, 84, 255);
      border-right: none;
      box-shadow: 0 0 0 2px rgb(236 230 194 / 10%);
    } */
  }

  .ant-input-group-addon {
    border: 1px solid #ece6c2;
    background: none;
    color: rgb(77, 84, 255);
    cursor: pointer;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    :hover {
      border: 1px solid rgb(77, 84, 255);
      border-right: none;
      box-shadow: 0 0 0 2px rgb(236 230 194 / 10%);
    }
  }
  /* box-shadow: 0 0 0 2px rgb(236 230 194 / 10%); */
`

const RadioGroup = styled(Radio.Group)`
  width: 100%;
  /* border: 2px solid rgba(133, 133, 133, 0.1); */
  .ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-13tohg).ant-radio-button-wrapper-disabled) {
    background-color: rgb(77, 84, 255);
  }
  .ant-radio-button-wrapper {
  }
  > label {
    width: 50%;
    text-align: center;
  }
  margin-bottom: 20px;
  > label:hover {
    /* background-color: rgb(77, 84, 255); */
  }
`

const IDoButton = styled(Button)`
  width: 100%;
  height: 42px;
  background-color: rgb(77, 84, 255);
  font-weight: 500;
  border-radius: 10px;

  margin-top: 20px;

  border: none !important;
  :hover {
    opacity: 0.5;
    /* border: 1px solid rgb(77, 84, 255) !important; */
    color: #fff !important;
    /* box-shadow: 0 0 0 2px rgb(236 230 194 / 10%); */
  }

  span {
    color: #fff;
  }

  .ant-btn-default:disabled {
    opacity: 0.5;
  }
`

const Div = styled.div``

const CreamPresale = () => {
  const params = useParams()

  console.log(params[0])

  const dispatch = useAppDispatch()
  const { chainId, account } = useActiveWeb3React()
  const presaleAddress = params[0]
  const TranslateString = useI18n()
  const [value1, setValue1] = useState('1')
  const [typedValue, setTypedValue] = useState('')
  const store = useAppSelector((state) => state.presale)
  const { mainPresaleState, mainUserState } = store

  const mainCurrency = wrappedCurrency(useCurrency(mainPresaleState?.EthCurrency), chainId)
  const currency = useCurrency(mainPresaleState?.Currency)

  const mainPresale: MainPresaleState = useMemo(() => {
    return {
      ...mainPresaleState,
      personalMaxAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.personalMaxAmount || '0'),
      personalMinAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.personalMinAmount || '0'),
      stakeAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.stakeAmount || '0'),
      oneStakeAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.oneStakeAmount || '0'),
      onePresale: {
        ...mainPresaleState?.onePresale,
        upperLimit: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.onePresale.upperLimit || '0'),
        userMaxAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.onePresale.userMaxAmount || '0'),
        userMinAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.onePresale.userMinAmount || '0'),
      },
      towPresale: {
        ...mainPresaleState?.towPresale,
        upperLimit: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.towPresale.upperLimit || '0'),
        userMaxAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.towPresale.userMaxAmount || '0'),
        userMinAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.towPresale.userMinAmount || '0'),
      },
    }
  }, [mainCurrency, mainPresaleState])

  const mainUser: MainUserInfo = useMemo(() => {
    return {
      ...mainUserState,
      amount: mainCurrency && new TokenAmount(mainCurrency, mainUserState?.amount || '0'),
      fallbackAmount: mainCurrency && new TokenAmount(mainCurrency, mainUserState?.fallbackAmount || '0'),
      receivedAmount:
        currency && new TokenAmount(wrappedCurrency(currency, chainId), mainUserState?.receivedAmount || '0'),
    }
  }, [mainCurrency, mainUserState, currency, chainId])

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, mainCurrency && unwrappedToken(mainCurrency))

  const now = Date.now() / 1000
  const isOne = Date.now() / 1000 < mainPresale?.startTime + 1800
  console.log(`isOne: ${isOne}`)

  const showTime: [string, number] = useMemo(() => {
    if (mainPresale.End) return ['End of Pre-sale', 0]
    if (now < mainPresale.startTime) return ['Not started', mainPresale.startTime * 1000]
    if (now > mainPresale.startTime && now < mainPresale.endTime) {
      return ['Under execution', mainPresale.endTime * 1000]
    }
    return ['End of Pre-sale', 0]
  }, [mainPresale, now])

  const showStartTime: number = useMemo(() => {
    if (value1 === '1') {
      if (now > mainPresale.startTime) return 0
      return mainPresale.startTime * 1000
    }
    if (!isOne) return 0
    return (mainPresale?.startTime + 1800) * 1000
  }, [mainPresale, isOne, now, value1])

  const oneError = useMemo(() => {
    const parseValue = utils.parseUnits(typedValue || '0', mainCurrency?.decimals || 18)
    if (now < mainPresale.startTime) return `Not started`
    if (!isOne) return `Ended`
    if (mainPresale.oneStakeAmount?.equalTo(mainPresale?.onePresale?.upperLimit || '0')) {
      return `Reaching limit`
    }
    if (isOne && !mainUser.oneWhite) return `Not white`

    if (BigNumber.from(selectedCurrencyBalance?.raw.toString() || '0').lt(parseValue)) return `Insufficient Balance`
    const min = isOne ? mainPresale?.onePresale.userMinAmount : mainPresale?.towPresale.userMinAmount
    const max = isOne ? mainPresale?.onePresale.userMaxAmount : mainPresale?.towPresale.userMaxAmount
    if (!parseValue.eq('0') && parseValue.lt(min.raw.toString())) return `Less than minimum limit`
    if (!parseValue.eq('0') && parseValue.gt(max.raw.toString())) return `Greater than maximum limit`
    return null
  }, [mainPresale, now, isOne, typedValue, selectedCurrencyBalance, mainCurrency, mainUser])

  const twoError = useMemo(() => {
    const parseValue = utils.parseUnits(typedValue || '0', mainCurrency?.decimals || 18)
    if (now < mainPresale.startTime || isOne) return `Not started`
    if (now > mainPresale?.endTime || mainPresale.End) return `Ended`
    if (mainPresale.towPresale.isWhite && !mainUser.twoWhite) return `Not white`
    if (mainPresale.stakeAmount?.equalTo(mainPresale?.personalMaxAmount || '0')) return `Reaching limit`

    if (BigNumber.from(selectedCurrencyBalance?.raw.toString() || '0').lt(parseValue)) return `Insufficient Balance`
    const min = isOne ? mainPresale?.onePresale.userMinAmount : mainPresale?.towPresale.userMinAmount
    const max = isOne ? mainPresale?.onePresale.userMaxAmount : mainPresale?.towPresale.userMaxAmount
    if (!parseValue.eq('0') && parseValue.lt(min.raw.toString())) return `Less than minimum limit`
    if (!parseValue.eq('0') && parseValue.gt(max.raw.toString())) return `Greater than maximum limit`
    return null
  }, [mainPresale, now, isOne, typedValue, selectedCurrencyBalance, mainCurrency, mainUser])

  const error = value1 === '1' ? oneError : twoError

  const liquidityError = useMemo(() => {
    if (now < mainPresale?.endTime && !mainPresale.End) return `Not finished`
    if (mainPresale.Liquidity) return 'Added'
    return undefined
  }, [now, mainPresale])

  const claimError = useMemo(() => {
    if (now < mainPresale?.endTime && !mainPresale.End) return `Not finished`
    if (!mainPresale.Liquidity) return `Not Liquidity`
    if (!mainUser.amount?.greaterThan('0')) return 'Not stake'
    if (mainUser.isDraw) return 'Received'
    return undefined
  }, [now, mainPresale, mainUser])

  const lpError = useMemo(() => {
    if (now < mainPresale?.endTime && !mainPresale.End) return `Not finished`
    if (!mainPresale.Liquidity) return `Not Liquidity`
    return undefined
  }, [now, mainPresale])

  const [approvalA, approveACallback] = useApproveCallback(
    mainCurrency &&
      CurrencyAmount.fromRawAmount(unwrappedToken(mainCurrency), utils.parseEther(typedValue || '0').toString()),
    presaleAddress
  )

  const optionsWithDisabled = [
    { label: TranslateString(1164, 'One'), value: '1' },
    { label: TranslateString(1164, 'Two'), value: '2' },
  ]

  useEffect(() => {
    console.log(account)
    dispatch(fetchPresaleDataAsync({ chainId, account, presaleAddress }))
    if (account) {
      dispatch(fetchPresaleUserDataAsync({ chainId, account, presaleAddress }))
    }
    return () => {
      console.log('')
    }
  }, [dispatch, chainId, presaleAddress, account])

  const fromRawAmount =
    mainCurrency &&
    CurrencyAmount.fromRawAmount(mainCurrency, utils.parseUnits(typedValue || '0', mainCurrency.decimals).toString())

  const showInfo: [number, CurrencyAmount, CurrencyAmount] = useMemo(() => {
    if (value1 === '1') {
      return [
        (Number(mainPresale?.oneStakeAmount?.toSignificant() || '0') * 100) /
          Number(mainPresale?.onePresale.upperLimit?.toSignificant() || '0'),
        mainPresale?.onePresale.upperLimit,
        mainPresale?.oneStakeAmount,
      ]
    }
    return [
      (Number(mainPresale?.stakeAmount?.toSignificant() || '0') * 100) /
        Number(mainPresale?.personalMaxAmount?.toSignificant() || '0'),
      mainPresale?.personalMaxAmount,
      mainPresale.stakeAmount,
    ]
  }, [mainPresale, value1])

  const {
    reqLoaderDeposit,
    reqLoaderClaim,
    reqLoaderClaimEnd,
    reqLoaderClaimLp,
    depositCallback,
    claimCallback,
    claimEndCallback,
    claimLpCallback,
  } = usePresaleAll({ address: presaleAddress, toeknAmount: fromRawAmount })
  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio4 checked', value)
    setValue1(value)
  }
  return (
    <IDOHTML>
      <div className="main">
        <div className="presaleTop">
          <div className="title_time">
            <p> {TranslateString(1, showTime[0])}</p>
            <div>
              <CountDown endTime={showTime[1]} type={null} timeOver={null} />
            </div>
          </div>
          {mainPresale?.lockLpOwner === account && <p style={{ width: '20px' }} />}
          {mainPresale?.lockLpOwner !== undefined && mainPresale?.lockLpOwner === account && (
            <div className="liquidity">
              <div className="title_time">
                <p>{TranslateString(1, 'Add Liquidity')}</p>
                <AutoColumn gap="md">
                  <Button
                    disabled={!!liquidityError || reqLoaderClaimEnd}
                    style={{ height: '42px', width: '100%' }}
                    onClick={claimEndCallback}
                  >
                    {reqLoaderClaimEnd ? (
                      <Dots>{TranslateString(1164, 'add liquidity')}</Dots>
                    ) : (
                      TranslateString(1164, liquidityError || 'add liquidity')
                    )}
                  </Button>
                </AutoColumn>
              </div>
              <p style={{ width: '20px' }} />
              <div className="title_time lp">
                <p>{TranslateString(1, 'Claim Lp')}</p>
                {!lpError && now < mainPresale.lockLpTime ? (
                  <CountDown endTime={mainPresale.lockLpTime * 1000} type={null} timeOver={null} />
                ) : (
                  <AutoColumn gap="md">
                    <Button
                      disabled={!!lpError || reqLoaderClaimLp}
                      style={{ height: '42px', width: '100%' }}
                      onClick={claimLpCallback}
                    >
                      {reqLoaderClaimLp ? (
                        <Dots>{TranslateString(1164, 'claim Lp')}</Dots>
                      ) : (
                        // TranslateString(1164, lpError || 'claim Lp')
                        TranslateString(1164, lpError || 'claim Lp')
                      )}
                    </Button>
                  </AutoColumn>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="presaleInfo">
          <div className="ido">
            <div>
              <RadioGroup
                options={optionsWithDisabled}
                onChange={onChange4}
                value={value1}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <span className="title">{`[${mainCurrency && unwrappedToken(mainCurrency)?.symbol}-${
              currency?.symbol
            }]`}</span>
            <div className="progress">
              <div className="s1">
                {currency?.symbol} Token <span>{showInfo[0].toFixed(2)}%</span>
              </div>
              <IdoProgress percent={showInfo[0]} showInfo={false} strokeColor="#6f66f6" />
              <div className="edu">
                <span>
                  {showInfo[2]?.toSignificant()} {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                </span>
                <span>
                  {showInfo[1]?.toSignificant()} {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                </span>
              </div>
            </div>
            {/* <div className="pa1"></div> */}
            <div className="purchase">
              <div className="balance">
                <div className="t">
                  {TranslateString(1, 'White')}{' '}
                  <span>
                    {(value1 === '1' && mainUser?.oneWhite) || (value1 !== '1' && mainUser?.twoWhite)
                      ? TranslateString(1, 'You are on the whitelist')
                      : TranslateString(1, 'You are not in whitelist')}
                  </span>
                </div>
                <div className="t">
                  {TranslateString(1, 'Amount')}{' '}
                  <span>
                    {TranslateString(1, 'Balance')}: {selectedCurrencyBalance?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </div>
                <IdoInput
                  value={typedValue}
                  placeholder="0"
                  onChange={(e) => setTypedValue(e.target.value)}
                  suffix={
                    <Div
                      onClick={() => {
                        const max =
                          value1 === '1' ? mainPresale?.onePresale.userMaxAmount : mainPresale?.towPresale.userMaxAmount

                        console.log(`max: ${max.toSignificant()}`)
                        if (selectedCurrencyBalance.greaterThan(max)) {
                          setTypedValue(max?.toSignificant())
                        } else {
                          setTypedValue(selectedCurrencyBalance?.toSignificant())
                        }
                      }}
                    >
                      MAX
                    </Div>
                  }
                />
              </div>
              {showStartTime > 0 && (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <CountDownV2 endTime={Date.now() + 10000000} type={null} timeOver={null} />
                </div>
              )}

              {/* <IDoButton>{TranslateString(1, 'Subscrilbe')}</IDoButton> */}
              {!account ? (
                <ConnectWalletButton width="100%" style={{ height: '42px', marginTop: '20px' }} />
              ) : (
                <AutoColumn gap="md">
                  {approvalA !== ApprovalState.APPROVED ? (
                    <RowBetween>
                      <Button
                        onClick={() => {
                          try {
                            approveACallback()
                          } catch (e) {
                            console.log(e)
                          }
                        }}
                        disabled={approvalA === ApprovalState.PENDING}
                        style={{ width: '100%', height: '42px', marginTop: '20px' }}
                      >
                        {approvalA === ApprovalState.PENDING ? (
                          <Dots>Approving {mainCurrency?.symbol}</Dots>
                        ) : (
                          `Approve ${mainCurrency?.symbol}`
                        )}
                      </Button>
                    </RowBetween>
                  ) : (
                    <Button
                      disabled={!!error || reqLoaderDeposit}
                      style={{ height: '42px', marginTop: '20px' }}
                      onClick={depositCallback}
                    >
                      {reqLoaderDeposit ? (
                        <Dots>{TranslateString(1164, error || 'Buy')}</Dots>
                      ) : (
                        TranslateString(1164, error || 'Buy')
                      )}
                    </Button>
                  )}
                </AutoColumn>
              )}
            </div>
            <div className="claimable claimable1">
              {/* <div>
                {TranslateString(1, 'Fallback')} {mainCurrency && unwrappedToken(mainCurrency)?.symbol}{' '}
                <span>{mainUser?.fallbackAmount?.toSignificant()}</span>
              </div> */}
              <div>
                {`${currency?.symbol} `}
                {TranslateString(1, 'Received Quantity')}:<span>{mainUser?.receivedAmount?.toSignificant()}</span>
              </div>

              <AutoColumn gap="md">
                <Button
                  disabled={!!claimError || reqLoaderClaim}
                  style={{ height: '42px', marginTop: '20px' }}
                  onClick={claimCallback}
                >
                  {reqLoaderClaim ? (
                    <Dots>{TranslateString(1164, claimError || 'Receive')}</Dots>
                  ) : (
                    TranslateString(1164, claimError || 'Receive')
                  )}
                </Button>
              </AutoColumn>
            </div>
          </div>
          <div className="ido2">
            <div className="tp">
              <span className="title">{currency?.symbol} Token </span>

              <div className="sale">
                {TranslateString(
                  1,
                  'The following is the detailed information of pre-sale allocation. Please choose the first or second tier to participate in pre-sales based on community activities.'
                )}
              </div>

              <div className="info" style={{ backgroundColor: 'none' }}>
                <p>
                  {TranslateString(1, 'Pre Sale totalSupply')}:{' '}
                  <span>
                    {mainPresale?.personalMaxAmount?.multiply(mainPresale?.presalePrice || '0').toSignificant(6)}{' '}
                    {currency?.symbol}
                  </span>
                </p>

                <p>
                  {TranslateString(1, 'Hard top')}:{' '}
                  <span>
                    {mainPresale.personalMaxAmount?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'Soft top')}:{' '}
                  <span>
                    {mainPresale.personalMinAmount?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'Mode')}:{' '}
                  <span>
                    {isOne || mainPresale?.towPresale.isWhite ? TranslateString(1, 'White') : TranslateString(1, 'All')}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'Pre Sale Price')}:{' '}
                  <span>{`1 ${mainCurrency && unwrappedToken(mainCurrency)?.symbol} = ${mainPresale?.presalePrice} ${
                    currency?.symbol
                  }`}</span>
                </p>
                <p>
                  {TranslateString(1, 'Liquidity price')}:{' '}
                  <span>{`1 ${mainCurrency && unwrappedToken(mainCurrency)?.symbol} = ${mainPresale?.liquidityPrice} ${
                    currency?.symbol
                  }`}</span>
                </p>
                <p>
                  {TranslateString(1, 'Liquidity ratio')}: <span>{mainPresale?.liquidityRatio}%</span>
                </p>
                <p>
                  {TranslateString(1, 'One Minimun Buy')}:{' '}
                  <span>
                    {mainPresale?.onePresale?.userMinAmount?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'One Maximun Buy')}:{' '}
                  <span>
                    {mainPresale?.onePresale?.userMaxAmount?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'Two Minimun Buy')}:{' '}
                  <span>
                    {mainPresale?.towPresale?.userMinAmount?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'Two Maximun Buy')}:{' '}
                  <span>
                    {mainPresale?.towPresale?.userMaxAmount?.toSignificant()}{' '}
                    {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                  </span>
                </p>
                <p>
                  {TranslateString(1, 'My Buy')}:{' '}
                  <span>
                    {mainUser?.amount?.toSignificant()} {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
                    {/* <Loader top="9" stroke="#6f66f6" /> */}
                  </span>
                </p>
              </div>

              {/* <IDoButton
            // disabled={reqSubscribe || store.ido2?.isEnd}
            >
              {TranslateString(1, '结束 IDO')}
            </IDoButton> */}
            </div>
          </div>
        </div>
      </div>
    </IDOHTML>
  )
}

const ListContrainer = styled.div`
  width: 1080px;
  margin: 0px auto;
  box-sizing: border-box;
  @media screen and (min-width: 360px) {
    width: 100%;
    padding: 0px 15px;
  }
  @media screen and (min-width: 576px) {
    width: 1080px;
    padding: 0px;
  }
`

const ListHeader = styled.div`
  font-size: 28px;
  padding: 20px 0px;
  font-weight: 600;
  border-bottom: 1px solid #00000088;
`

const PresaleDiv = styled.div`
  margin-top: 20px;
`

const Presale = styled.div`
  background-color: #fff;
  padding: 15px;
  padding-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  > p {
    font-size: 13px;
    text-align: center;
    padding: 3px 0px;
    background-color: #f9f7f8;
  }
  > div {
    margin: 15px 0px 0px 0px;
    font-size: 14px;
    .title {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ant-progress {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .time {
    p {
      margin: 3px 0px;
    }
  }
  .name_title {
    margin-top: 0px;
    margin-bottom: 5px;
    text-align: center;
    h1 {
      font-size: 30px;
      text-align: center;
    }
  }

  .progress {
    > div {
      span {
        float: right;
      }
      p {
        font-size: 18px;
        font-weight: 600;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
`

/* eslint-disable */
function timestampToFormattedTime(timestamp) {
  // 创建一个Date对象，将时间戳乘以1000转换为毫秒
  const date = new Date(timestamp * 1000)

  // 获取年、月、日、时、分、秒
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2) // 月份从0开始，因此要加1
  const day = ('0' + date.getDate()).slice(-2)
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)

  // 格式化成 "YYYY-MM-DD HH:mm:ss" 形式
  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`

  return formattedTime
}

const PresaleHtml: React.FC<React.PropsWithChildren<{ store: PresaleState; chainId: number }>> = ({
  store,
  chainId,
}) => {
  const mainPresaleState = store

  const TranslateString = useI18n()

  const now = Date.now() / 1000
  const showTime: [string, number, boolean] = useMemo(() => {
    if (mainPresaleState.End) return ['End of Pre-sale', 0, false]
    if (now < mainPresaleState.startTime) return ['Not started', mainPresaleState.startTime * 1000, true]
    if (now > mainPresaleState.startTime && now < mainPresaleState.endTime) {
      return ['Under execution', mainPresaleState.endTime * 1000, true]
    }
    return ['End of Pre-sale', 0, false]
  }, [mainPresaleState, now])

  const mainCurrency = wrappedCurrency(useCurrency(mainPresaleState?.EthCurrency), chainId)
  const currency = useCurrency(mainPresaleState?.Currency)

  const mainPresale: MainPresaleState = useMemo(() => {
    return {
      ...mainPresaleState,
      personalMaxAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.personalMaxAmount || '0'),
      personalMinAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.personalMinAmount || '0'),
      stakeAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.stakeAmount || '0'),
      oneStakeAmount: mainCurrency && new TokenAmount(mainCurrency, mainPresaleState?.oneStakeAmount || '0'),
      onePresale: {
        ...mainPresaleState?.onePresale,
        upperLimit: undefined,
        userMaxAmount: undefined,
        userMinAmount: undefined,
      },
      towPresale: {
        ...mainPresaleState?.towPresale,
        upperLimit: undefined,
        userMaxAmount: undefined,
        userMinAmount: undefined,
      },
    }
  }, [mainCurrency, mainPresaleState])

  const progress =
    (Number(mainPresale?.stakeAmount?.toSignificant() || '0') * 100) /
    Number(mainPresale?.personalMaxAmount?.toSignificant() || '0')

  return (
    <Presale>
      <div className="name_title">
        <h1>{currency?.symbol}</h1>
      </div>
      <p> {TranslateString(1, showTime[0])}</p>
      <div className="time">
        <div>
          <p>
            {TranslateString(1, 'OneStartTime')}: {timestampToFormattedTime(mainPresaleState.startTime)}{' '}
          </p>
          <p>
            {TranslateString(1, 'TwoStartTime')}: {timestampToFormattedTime(mainPresaleState.startTime + 1800)}
          </p>
          <p>
            {TranslateString(1, 'EndTime')}: {timestampToFormattedTime(mainPresaleState.endTime)}
          </p>
        </div>
      </div>
      <div className="progress">
        <div>
          <p>{TranslateString(1, 'Ratio')}: </p> 1 {mainCurrency && unwrappedToken(mainCurrency)?.symbol} ={' '}
          {mainPresale.presalePrice} {currency?.symbol}
          <span>{progress} %</span>
        </div>
        <IdoProgress percent={progress} showInfo={false} strokeColor="#6f66f6" />
        <div>
          {mainPresale?.stakeAmount?.toSignificant()} {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
          <span>
            {mainPresale?.personalMaxAmount?.toSignificant()} {mainCurrency && unwrappedToken(mainCurrency)?.symbol}
          </span>
        </div>
      </div>
      <div>
        <Button style={{ borderRadius: '30px', height: '42px' }} width="100%">
          {showTime[2] ? TranslateString(1, `Buy`) : TranslateString(1, `Detail`)}
        </Button>
      </div>
    </Presale>
  )
}

export const CreamPresaleList = () => {
  const dispatch = useAppDispatch()
  const { chainId, account } = useActiveWeb3React()
  const store = useAppSelector((state) => state.presale)
  const { presaleList } = store
  const TranslateString = useI18n()
  useEffect(() => {
    dispatch(fetchCreamPresaleListAsync({ chainId }))
  }, [dispatch, chainId])

  return (
    <ListContrainer style={{ marginTop: '20px' }}>
      <div>
        <ListHeader>{TranslateString(1, `CreamPresale`)}</ListHeader>
        <PresaleDiv>
          {presaleList.map((pres, index) => (
            <a href={`/#/presale/${pres?.address}`}>
              <PresaleHtml store={pres} chainId={chainId} />
            </a>
          ))}
        </PresaleDiv>
      </div>
    </ListContrainer>
  )
}

export default CreamPresale
