import React from 'react'
import styled from 'styled-components'
import PanelBody from './PanelBody'
// import PanelFooter from './PanelFooter'
import { SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from '../config'
import { PanelProps, PushedProps } from '../types'
import PanelFooter from './PanelFooter'

interface Props extends PanelProps, PushedProps {
  showMenu: boolean
  isMobile: boolean
}

const StyledPanel = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  position: fixed;
  margin-top: ${({ showMenu }) => (showMenu ? '63px' : 0)};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: #fff;
  // leftSidebar.png
  padding-top: 10px;

  width: 100%;
  /* background-image: url('./leftSidebar.png') !important;
  background-size: cover !important;

  background-repeat: no-repeat !important;

  ${({ theme }) => theme.mediaQueries.xs} {
    background-position-x: -25px;
    background-position-y: 60px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    background-position-x: -155px;
    background-position-y: 60px;
  } */
  /* background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important; */

  width: ${({ isPushed }) => (isPushed ? `${SIDEBAR_WIDTH_FULL}px` : 0)};
  height: 100vh;
  height: calc(100vh - 63px);
  transition: padding-top 0.2s, width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-right: ${({ isPushed }) => (isPushed ? '2px solid rgba(133, 133, 133, 0.1)' : 0)};
  z-index: 11;
  overflow: ${({ isPushed }) => (isPushed ? 'initial' : 'hidden')}
  ;
  transform: translate3d(0, 0, 0);
  ${({ isPushed }) => !isPushed && 'white-space: nowrap;'};

  ${({ theme }) => theme.mediaQueries.nav} {
    border-right: 2px solid rgba(133, 133, 133, 0.1);
    width: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
  }
`

const Panel: React.FC<Props> = (props) => {
  const { isPushed, showMenu } = props

  return (
    <StyledPanel isPushed={isPushed} showMenu={showMenu}>
      <PanelBody {...props} />
      <PanelFooter {...props} />
    </StyledPanel>
  )
}

export default Panel
