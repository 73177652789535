import React, { useContext } from 'react'
import { Menu as UikitMenu, MenuEntry } from 'pancake-uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import { useI18nText } from 'hooks/useI18n'
import { usePrice } from 'state/farms/hooks'
import links from './config'

/* eslint-disable */
const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakeBusdPrice =  usePrice()
  const profile = useGetLocalProfile()

  const TranslateString = useI18nText()
  const linkList = links.map((link: MenuEntry) => {
    return {
      ...link,
      label: TranslateString(link.label),
      items:
        link.items === undefined
          ? ''
          : link.items.map((item) => {
              return {
                ...item,
                label: TranslateString(item.label),
              }
            }),
    }
  })

  return (
    <UikitMenu
      links={linkList}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakeBusdPrice}
      profile={profile}
      {...props}
    />
  )
}

export default Menu
