import { useMemo } from 'react'
import { kebabCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { push as pushToast, remove as removeToast, clear as clearToast } from 'state/actions'

export const toastTypes = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
}
export type Types = typeof toastTypes[keyof typeof toastTypes]
export interface ToastAction {
  text: string
  url: string
}
export interface Toast {
  id: string
  type: Types
  title: string
  description?: string
  action?: ToastAction
}

/* eslint-disable */
// Toasts
const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

export default useToast
