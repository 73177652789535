import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button/Button'
import { useWalletModal } from '../../WalletModal'
import { Login } from '../../WalletModal/types'

interface Props {
  account?: string
  login: Login
  logout: () => void
}

export const UserWallet = styled(Button)`
  border-radius: 10px;
  background: #6f66f6; // linear-gradient(90deg, #3fa87b, #d1c84d) !important;
  opacity: 1;
  color: #fff;
  box-shadow: none;
  border-radius: 20px;
`

export const UserToken = styled(Button)`
  border-radius: 10px;
  background: linear-gradient(90deg, #3fa87b, #d1c84d) !important;
  opacity: 1;
  margin-right: 10px;
`

export const DivToken = styled(Button)`
  background: none;
  position: relative;
  box-shadow: none;
  padding: 0px;
`

export const UserTokenPrice: React.FC<Props> = ({ account, login, logout }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  return (
    <div>
      <UserToken scale="sm">0.001</UserToken>
    </div>
  )
}

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  return (
    <DivToken>
      {account ? (
        <UserWallet
          scale="sm"
          variant="tertiary"
          onClick={() => {
            onPresentAccountModal()
          }}
        >
          {accountEllipsis}
        </UserWallet>
      ) : (
        <UserWallet
          scale="sm"
          onClick={() => {
            onPresentConnectModal()
          }}
        >
          Connect Wallet
        </UserWallet>
      )}
    </DivToken>
  )
}

export default React.memo(UserBlock, (prevProps, nextProps) => prevProps.account === nextProps.account)
