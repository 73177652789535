import { ChainId } from 'pancake-sdk'

export const DEFAULT_TOKEN_LIST_URL = 'pancakeswap'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const DEFAULT_CHAINID: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'https://tokenlists.creamswap.app/Creamswap.json',
  // [ChainId.ARBITRUM]: 'https://raw.githubusercontent.com/MangoSwapGitHub/MangoSwap/main/Tokens/arbilist.json',
}
