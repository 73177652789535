import { AxiosRequestConfig } from 'axios'
import create from './requests'


// const baseURL = 'http://localhost:4008/'
const baseURL = 'https://www.creamdata.app/'
// const baseURL = 'http://10.168.1.101:4008/'

const onFulfilled = (config: AxiosRequestConfig | any) => {
  // @ts-ignore
  if (config.url.slice(0, 24) === global.mapHost || config.url === global.ossHost) {
    delete config.headers.Authorization
  } else {
    const ats = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token') as string) : null
    if (ats !== null) {
      config.headers.Authorization = `Bearer ${ats.token}`
    }
  }

  return config
}

const request = create(baseURL, onFulfilled)

export default request
