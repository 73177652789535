import styled from 'styled-components'
import Button from './Button'
import { BaseButtonProps, PolymorphicComponent } from './types'

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps>`
  /* background-color: #fff; */
  padding: 0;
  height: 40px;
  border-radius: 10px;
  width: ${({ scale }) => (scale === 'sm' ? '32px' : '40px')};
`

export default IconButton
