import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal, TuneIcon, HistoryIcon } from 'pancake-uikit'
import useI18n from 'hooks/useI18n'
import Logo from 'components/Logo'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
  show?: boolean
}

const StyledPageHeader = styled.div`
  /* background-image: url('./styledPageHeader.png') !important; */
  /* background-repeat: no-repeat !important; */
  /* background-size: cover !important;
  background-position-y: -44px; */
  /* background-position: center !important; */
  border-bottom: 2px solid #f9f7f8; //${({ theme }) => theme.colors.borderColor};
  padding: 24px;
  background-color: #fff;
  /* margin-bottom: 15px; */
`

const Details = styled.div`
  flex: 1;
`

const MainLogo = styled.div`
  width: 45px;
  margin-right: 10px;
`

const PageHeader = ({ title, description, children, show }: PageHeaderProps) => {
  const TranslateString = useI18n()
  const [onPresentSettings] = useModal(<SettingsModal translateString={TranslateString} />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal translateString={TranslateString} />)

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        {/* <MainLogo>
          <Logo srcs={['./favicon.png']} />
        </MainLogo> */}
        <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <Text color="#000" fontSize="14px">
              {description}
            </Text>
          )}
        </Details>
        {!show && (
          <>
            <IconButton variant="text" onClick={onPresentSettings} title={TranslateString(1200, 'Settings')}>
              <TuneIcon width="24px" color="#6f66f6" />
            </IconButton>
            <IconButton
              variant="text"
              onClick={onPresentRecentTransactions}
              title={TranslateString(1202, 'Recent transactions')}
            >
              <HistoryIcon width="24px" color="#6f66f6" />
            </IconButton>
          </>
        )}
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
