import React from 'react'
import styled from 'styled-components'
import { CogIcon } from '../../../components/Svg'
import IconButton from '../../../components/Button/IconButton'
import { MENU_ENTRY_HEIGHT } from '../config'
import { PanelProps, PushedProps } from '../types'
import CakePrice from './CakePrice'
import ThemeSwitcher from './ThemeSwitcher'
import SocialLinks from './SocialLinks'
import LangSelector from './LangSelector'

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
  /* background: linear-gradient(90deg,#232850,#352b43) !important; */
  border-top: solid 2px rgba(133, 133, 133, 0.1);
`

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 8px;
`

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
  ${({ theme }) => theme.mediaQueries.xs} {
    width: 95%;
    padding-right: 60px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 26px;
  }
`

const CatkingBody = styled.div``

const PanelFooter: React.FC<Props> = ({
  isPushed,
  pushNav,
  toggleTheme,
  isDark,
  cakePriceUsd,
  currentLang,
  langs,
  setLang,
}) => {
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon color="#fff" />
        </IconButton>
      </Container>
    )
  }

  return (
    <Container>
      <CatkingBody>
        <CakePrice cakePriceUsd={cakePriceUsd} />
      </CatkingBody>

      <SocialEntry>
        <SocialLinks />
        {/* <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} /> */}
      </SocialEntry>
      {/* <SettingsEntry>
        <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
        <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
      </SettingsEntry> */}
    </Container>
  )
}

export default PanelFooter
