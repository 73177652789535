import { useCallback, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'state'
import { estimateGas } from 'utils/calls/estimateGas'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCreamGameContract } from 'hooks/useContractV2'

import { fetchGameDataAsync, fetchGameUserDataAsync } from 'state/game'
import { message } from 'antd'
import { match } from 'utils/match'

export const useGameAll = ({ code, rank_level }: { code: number; rank_level: number }) => {
  const { reqLoader: reqLoaderRegister, registerCallback } = useRegister({ code })
  const { reqLoader: reqLoaderUpgrade, upgradeCallback } = useUpgrade({ rank_level })
  const { reqLoader: reqLoaderClaimLp, claimEndCallback } = useClaimDividend()

  return {
    reqLoaderRegister,
    reqLoaderUpgrade,
    reqLoaderClaimLp,
    registerCallback,
    upgradeCallback,
    claimEndCallback,
  }
}

export const useRegister = ({ code }: { code: number }) => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const gameContract = useCreamGameContract()

  const registerCallback = useCallback(async () => {
    try {
      setReqLoader(true)

      const upperReferrer = await gameContract.findAndPlaceUpperReferrer(code)
      const gasLimit = await estimateGas(gameContract, 'register', [code, upperReferrer], 1000)
      const tx = await gameContract.register(code, upperReferrer, {
        gasLimit,
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchGameUserDataAsync({ chainId, account }))
        dispatch(fetchGameDataAsync({ chainId }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      message.error(match(e.toString()) || e.toString())
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, gameContract, code, chainId, account])

  return { reqLoader, registerCallback }
}

export const useUpgrade = ({ rank_level }: { rank_level: number }) => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const gameContract = useCreamGameContract()

  const upgradeCallback = useCallback(async () => {
    try {
      setReqLoader(true)
      const gasLimit = await estimateGas(gameContract, 'upgrade', [rank_level], 1000)
      const tx = await gameContract.upgrade(rank_level, { gasLimit })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchGameUserDataAsync({ chainId, account }))
        dispatch(fetchGameDataAsync({ chainId }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      message.error(match(e.toString()) || e.toString())
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, gameContract, chainId, rank_level, account])

  return { reqLoader, upgradeCallback }
}

export const useClaimDividend = () => {
  const [reqLoader, setReqLoader] = useState(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const gameContract = useCreamGameContract()

  const claimEndCallback = useCallback(async () => {
    try {
      setReqLoader(true)
      const gasLimit = await estimateGas(gameContract, 'claimDividend', [], 1000)
      const tx = await gameContract.claimDividend({ gasLimit })
      const receipt = await tx.wait()
      if (receipt.status) {
        dispatch(fetchGameUserDataAsync({ chainId, account }))
        dispatch(fetchGameDataAsync({ chainId }))
      }
      setReqLoader(false)
    } catch (e) {
      setReqLoader(false)
      message.error(match(e.toString()) || e.toString())
      // error(match(e.toString()) || e.toString())
    }
  }, [dispatch, gameContract, chainId, account])

  return { reqLoader, claimEndCallback }
}
