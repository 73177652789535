import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getMulticallAddress,
  getAddress,
  getMasterChefAddress,
  getCakeVaultAddress,
  getIfoPoolAddress,
  getCreamFundAddress,
} from 'utils/addressHelpers'

// ABI
import ERC20_ABI from 'config/abi/erc20.json'

import MultiCallAbi from 'config/abi/multicall.json'
import masterChef from 'config/abi/masterchef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import cakeAbi from 'config/abi/cake.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import sousChef from 'config/abi/sousChef.json'
import ifoPoolAbi from 'config/abi/ifoPool.json'
import creamFundAbi from 'config/abi/creamFund.json'
import creamPresaleAbi from 'config/abi/creamPresale.json'

import creamGameAbi from 'config/abi/creamGame.json'

import feeAbi from 'config/abi/feeOnTransferDetector.json'

// Types
import {
  Erc20,
  Multicall,
  SousChefV2,
  Masterchef,
  CakeVault,
  Cake,
  SousChef,
  IfoPool,
  CreamFund,
  CreamPresale,
  CreamGame,
  FeeOnTransferDetector,
} from 'config/abi/types'
import { PoolCategory } from 'config/constants/types'
import { ChainId } from 'pancake-sdk'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getERC20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ERC20_ABI, address, signer) as Erc20
}

export const getMulticallContract = (chainId?: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(chainId), signer) as Multicall
}

export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer) as SousChefV2
}

export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer) as Masterchef
}

export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer) as CakeVault
}

export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, tokens.cake.address, signer) as Cake
}

export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer) as SousChef
}

export const getIfoPoolContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoPoolAbi, getIfoPoolAddress(), signer) as IfoPool
}

export const getCreamFundContract = (chainId?: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(creamFundAbi, getCreamFundAddress(chainId), signer) as CreamFund
}

export const getCreamPresaleContract = (address?: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(creamPresaleAbi, address, signer) as CreamPresale
}

export const getCreamGameContract = (address?: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(creamGameAbi, address, signer) as CreamGame
}

export const getFeeOnTransferDetectorContract = (address?: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(feeAbi, address, signer) as FeeOnTransferDetector
}

export default {}
