import useI18n from 'hooks/useI18n'
import { ButtonMenuItem } from 'pancake-uikit'
import React, { Suspense, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const HomeDiv = styled.div`
  .div1 {
    background-color: #7166ffcc;
    width: 100%;
    overflow: hidden;

    ${({ theme }) => theme.mediaQueries.xs} {
      padding: 0px 5%;
      padding-bottom: 130px;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0px 10%;
    }
    .left {
      color: #fff;
      padding: 100px 0px;
      width: 60%;
      float: left;

      ${({ theme }) => theme.mediaQueries.xs} {
        padding: 20px 0px;
        width: 85%;
      }

      ${({ theme }) => theme.mediaQueries.sm} {
        padding: 100px 0px;
        width: 60%;
      }
      h1 {
        font-size: 40px;
      }
      p {
        margin: 20px 0px;
      }

      a {
        width: 150px;
        border-radius: 20px;
        height: 40px;
        background-color: #fff;
        color: #000;
        :hover {
          opacity: 0.5;
          background: #fff;
        }
        box-shadow: 2px 3px 5px #000;
      }
    }
    .right {
      width: auto;
      float: right;

      overflow: hidden;
      img {
        ${({ theme }) => theme.mediaQueries.xs} {
          height: 200px;
        }

        ${({ theme }) => theme.mediaQueries.sm} {
          height: 330px;
        }
      }
    }
    position: relative;
  }
  .divright {
    background: none;
    position: absolute;
    top: 12%;
    width: auto;
    right: 0px;
  }

  .divright2 {
    background: none;
    position: absolute;
    top: 62%;
  }

  .divright3 {
    background: none !important;
    position: absolute;
    ${({ theme }) => theme.mediaQueries.xs} {
      top: 18%;
      padding-top: 0px !important;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      top: 22%;
    }
  }
  .divright4 {
    background: none !important;
    position: absolute;
    ${({ theme }) => theme.mediaQueries.xs} {
      top: 59%;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      top: 71%;
      left: 0px;
    }
  }
  .div11 {
    background-color: #1bbdefcc;
  }

  .div2 {
    background-color: #ff61bbcc;
    /* opacity: 0.5; */
    width: 100%;
    overflow: hidden;
    ${({ theme }) => theme.mediaQueries.xs} {
      padding: 30px 5%;
      padding-top: 200px;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0px 10%;
    }
    .right {
      color: #fff;
      padding: 100px 0px;
      float: right;
      ${({ theme }) => theme.mediaQueries.xs} {
        padding: 20px 0px;
        width: 85%;
      }

      ${({ theme }) => theme.mediaQueries.sm} {
        padding: 100px 0px;
        width: 60%;
      }

      h1 {
        font-size: 40px;
      }
      p {
        margin: 20px 0px;
      }

      a {
        width: 150px;
        border-radius: 20px;
        height: 40px;
        background-color: #fff;
        color: #000;
      }
    }
    .left {
      float: left;
      img {
        ${({ theme }) => theme.mediaQueries.xs} {
          height: 200px;
        }

        ${({ theme }) => theme.mediaQueries.sm} {
          height: 330px;
        }
      }
    }
  }

  .div3 {
    background-color: #ff61bbcc;
    /* opacity: 0.5; */
    width: 100%;
    overflow: hidden;
    ${({ theme }) => theme.mediaQueries.xs} {
      padding: 0px 5%;
      padding-top: 150px;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0px 10%;
    }
    .right {
      color: #fff;
      float: right;
      ${({ theme }) => theme.mediaQueries.xs} {
        padding: 20px 0px;
        width: 85%;
      }

      ${({ theme }) => theme.mediaQueries.sm} {
        padding: 70px 0px;
        width: 60%;
      }
      h1 {
        font-size: 40px;
      }
      p {
        margin: 20px 0px;
      }

      a {
        width: 150px;
        border-radius: 20px;
        height: 40px;
        background-color: #fff;
        color: #000;
      }
    }
    .left {
      float: left;
      img {
        ${({ theme }) => theme.mediaQueries.xs} {
          height: 170px;
        }

        ${({ theme }) => theme.mediaQueries.sm} {
          height: 330px;
        }
      }
    }
  }

  .div22 {
    background-color: #b841f7cc;
  }

  .divbg {
    background-image: url('./div1bg.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }

  .divbg2 {
    background-image: url('./div2bg.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
  .divbg3 {
    background-image: url('./div3bg.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
  .divbg4 {
    background-image: url('./div4bg.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
  .divbg5 {
    background-image: url('./div5bg.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
`

const Home = () => {
  const TranslateString = useI18n()

  return (
    <HomeDiv>
      <div className="divbg">
        <div className="div1">
          <div className="left">
            <h1>Cream Swap</h1>
            <p>
              {' '}
              {TranslateString(
                1,
                'Cream swap is a DEX primarily serving Meme Token; Featuring a brand new economic model, aggregated transactions, and unique tiered pre-sales.'
              )}{' '}
            </p>
            <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
              Swap
            </ButtonMenuItem>
          </div>
        </div>
      </div>
      <div className="div1 divright">
        <div className="right">
          <img src="div1.png" alt="" />
        </div>
      </div>

      <div className="div2 divright3">
        <div className="left">
          <img src="div2.png" alt="" />
        </div>
      </div>

      <div className="divbg2">
        <div className="div2">
          <div className="right">
            {' '}
            <h1>{TranslateString(1, 'A brand new economic model')}</h1>
            <p>
              {' '}
              {TranslateString(
                1,
                '70% of the butter swap handling fee is allocated to the repurchase fund, which is automatically operated by the smart contract, and the repurchase right is fully controlled by the $Cream holder'
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="divbg3">
        <div className="div1 div11">
          <div className="left">
            <h1>{TranslateString(1, 'Repurchase Fund')}</h1>
            <p>
              {TranslateString(1, 'Anyone can use $Cream anytime By redeeming $BNB from the repurchase agreement, the number of tokens used for redemption will be 100% destroyed to ensure that the repurchase price can continue to rise. As the agreement revenue increases, the repurchase price will continue to increase to ensure the growth of the holder s profits.')}
            </p>
          </div>
        </div>
      </div>
      <div className="div1 divright2">
        <div className="right">
          <img src="div3.png" alt="" />
        </div>
      </div>

      <div className="div3 divright4">
        <div className="left">
          <img src="div4.png" alt="" />
        </div>
      </div>
      <div className="divbg4">
        <div className="div3 div22">
          <div className="right">
            {' '}
            <h1>{TranslateString(1, 'Token Economics')}</h1>
            <p>
              <span>{TranslateString(1, 'Total Tokens')}：100000000（1亿）</span> <br />
              <span>{TranslateString(1, 'Proportion sales')}：40000000（40%）</span> <br />
              <span>{TranslateString(1, 'Initial liquidity')}：40000000（40%）</span> <br />
              <span>{TranslateString(1, 'Reserve liquidity')}：10000000（10%）</span> <br />
              <span>{TranslateString(1, 'Operating proportion')}：5000000 （5%）</span> <br />
              <span>{TranslateString(1, 'Market proportion')}：5000000 （5%）</span> <br />
            </p>
          </div>
        </div>
      </div>
      {/* <div className="divbg2">
        <div className="div2">
          <div className="left">
            <img src="div2.png" alt="" />
          </div>
          <div className="right">
            {' '}
            <h1>全新的经济模型</h1>
            <p> 奶油swap手续费的70%分配到回购基金，完全由智能合约自动运行，回购权完全由 $Cream 持有者掌握；</p>
          </div>
        </div>
      </div>

      <div className="divbg3">
        <div className="div1 div11">
          <div className="left">
            <h1>回购基金</h1>
            <p>
              任何人都可以随时使用$Cream
              从回购合约中兑换$BNB，兑换使用的Token数量将100%销毁，以此保证回购价格可持续攀升，随着协议收入的增加，回购价格会持续增长，以此保障持有者利润增长。
            </p>
          </div>
          <div className="right">
            <img src="div3.png" alt="" />
          </div>
        </div>
      </div>

      <div className="divbg4">
        <div className="div3 div22">
          <div className="left">
            <img src="div4.png" alt="" />
          </div>
          <div className="right">
            {' '}
            <h1>代币经济学</h1>
            <p>
              <span>代币总量：100000000（1亿）</span> <br />
              <span>预售占比：40000000（40%）</span> <br />
              <span>初始流动性：40000000（40%）</span> <br />
              <span>备用流动性：10000000（10%）</span> <br />
              <span> 运营占比：5000000</span> <br />
              <span>做市占比：5000000 （5%）</span> <br />
            </p>
          </div>
        </div>
      </div> */}
    </HomeDiv>
  )
}

export default Home
