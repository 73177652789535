import React from 'react'
import styled from 'styled-components'
import { PancakeRoundIcon } from '../../../components/Svg'
import Text from '../../../components/Text/Text'
import Skeleton from '../../../components/Skeleton/Skeleton'
import { LogoFourIcon } from '../icons'

interface Props {
  cakePriceUsd?: number
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`

const Price = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
  /* background-color: aqua; */

  .left {
    position: absolute;
    left: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  .right {
    position: absolute;
    right: 15px;
    > div {
      color: #000;
    }
  }
`

const CakePrice: React.FC<Props> = ({ cakePriceUsd }) => {
  return cakePriceUsd ? (
    <Price>
      <span className="left">
        <LogoFourIcon />
      </span>
      <span className="right">
        <Text color="textSubtle" bold>{`$${cakePriceUsd}`}</Text>
      </span>
    </Price>
  ) : (
    <>
      <Price>
        <span className="left">
          <LogoFourIcon />
        </span>
        <span className="right">
          <Skeleton width={80} height={24} />
        </span>
      </Price>
    </>
  )
}

export default React.memo(CakePrice)
