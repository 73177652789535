import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import { Route, useHistory } from 'react-router-dom'
import { Avatar, Dropdown, Modal, Button, MenuProps, Select } from 'antd'
import { useMatchBreakpointsList } from 'pancake-uikit/hooks/useMatchBreakpoints'
import useToast from 'hooks/useToast'
import config from 'components/Menu/config'
// import Container from 'components/Layout/Container'
import Overlay from '../../components/Overlay/Overlay'
import Flex from '../../components/Box/Flex'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import Panel from './components/Panel'
import UserBlock, { UserTokenPrice } from './components/UserBlock'
import { NavProps } from './types'
import { MENU_HEIGHT, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from './config'
import './menu.css'
import LangSelector from './components/LangSelector'

/* eslint-disable */
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  width: 100%;
  /* background-image: url('./shu.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important; */
  background: #f9f7f8;

  ${({ theme }) => theme.mediaQueries.xs} {
    height: 100%;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 100vh;
  }
`

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 8px;
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
  position: fixed;
  /* opacity: 0.5; */
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: #fff;
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
  z-index: 20;
  transform: translate3d(0, 0, 0);
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  margin-top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : 0)};
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    margin-left: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
    max-width: ${({ isPushed }) => `calc(100% - ${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px)`};
  }
`

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`

const MainLogo = styled.div`
  position: absolute;
  ${({ theme }) => theme.mediaQueries.xs} {
    left: 40px;
    width: 128px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    left: 50px;
    width: 176px;
  }
`

const ChainIdLogo = styled.div`
  list-style: none !important;
  position: relative;
  margin-top: 8px;
  border-radius: 30px;
  ${({ theme }) => theme.mediaQueries.xs} {
    margin-right: 5px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  li {
    list-style: none;
  }
  .ant-select {
    border: none;
    .ant-select-selector {
      background-color: #fce19b !important;
      border: none;
      border-radius: 30px;
    }
  }
  .ant-select-selector {
    border: none;
    color: #000;
    border-radius: 30px;
    background: #fce19b; // linear-gradient(90deg, #3fa87b, #d1c84d) !important;
    ${({ theme }) => theme.mediaQueries.xs} {
      padding: 0px 0px;
      width: 60px;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0px 10px;
      width: 120px;
    }
  }
`

const CustomModal = styled(Modal)`
  .ant-modal {
    width: 320px !important;
  }
  .ant-modal-content {
    background-color: #fff;
    border-radius: 30px;
  }
  .ant-modal-header {
  }
  .ant-modal-body {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 120px;
  }
  .ant-modal-footer {
    button:nth-of-type(2) {
      display: none;
    }
    .ant-btn {
      display: block;
      margin: 0px auto;
      background: linear-gradient(90deg, #6848d2, #b2589a) !important;
      border-radius: 20px;
      height: 40px;
      padding: 0px 50px;
      color: #fff;
      :hover {
        border: none;
        opacity: 0.5;
      }
    }
  }
`

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  cakePriceUsd,
  links,
  // profile,
  children,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPushed, setIsPushed] = useState(!isMobile)
  const [showMenu, setShowMenu] = useState(true)
  const { toastSuccess, toastError } = useToast()
  const refPrevOffset = useRef(window.pageYOffset)

  const history = useHistory()

  React.useEffect(() => {
    let isModal = false
    const unlisten = history.listen((location) => {
      config // 当路径变化时，检查路径是否匹配要显示对话框的路径
        .filter((e) => e.modal)
        .map((e) => {
          if (e.href === location.pathname) {
            console.log(e.href)
            isModal = true
          }
        })

      if (isModal) {
        location.pathname = sessionStorage.getItem('location.pathname')
        setIsModalOpen(true)
        isModal = false
      } else {
        setIsModalOpen(false)
        sessionStorage.setItem('location.pathname', location.pathname)
      }
    })
    // 卸载组件时停止监听
    return () => unlisten()
  }, [history])

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const breakpoints = useMatchBreakpointsList()

  return (
    <Wrapper>
      <CustomModal
        title={
          <>
            {' '}
            <Avatar src="./favicon.png" style={{ marginRight: '10px' }} size={40} alt="" />
              SWAP
          </>
        }
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        ComingSoon!
      </CustomModal>
      <StyledNav showMenu={showMenu}>
        <MainLogo>
          <img src="./cat.png" alt="" />
        </MainLogo>
        <Logo
          isPushed={isPushed}
          togglePush={() => setIsPushed((prevState: boolean) => !prevState)}
          isDark={isDark}
          href={homeLink?.href ?? '/'}
        />
        <Flex>
          <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
          {/* {!breakpoints.isMobile ? <UserTokenPrice account={account} login={login} logout={logout} /> : <div />} */}
          <UserBlock account={account} login={login} logout={logout} />
        </Flex>
      </StyledNav>
      <BodyWrapper>
        <Panel
          isPushed={isPushed}
          isMobile={isMobile}
          showMenu={showMenu}
          isDark={isDark}
          toggleTheme={toggleTheme}
          langs={langs}
          setLang={setLang}
          currentLang={currentLang}
          cakePriceUsd={cakePriceUsd}
          pushNav={setIsPushed}
          links={links}
        />
        <Inner isPushed={isPushed} showMenu={showMenu}>
          {children}
        </Inner>
        <MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" />
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu as any
