import React from 'react'
import { Text } from 'pancake-uikit'
import { ChainId, Currency, currencyEquals, ETHER, Token } from 'pancake-sdk'
import styled from 'styled-components'

import useI18n from 'hooks/useI18n'
import { SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.tertiary)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.invertedContrast};
  }

  background-color: ${({ theme, disable }) => disable && theme.colors.tertiary};
  opacity: ${({ disable }) => disable && '0.4'};
`

const Div = styled.div`
  width: 100%;
  overflow-x: auto;
  display: -webkit-box;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`
const Wrapper = styled.div`
  width: 200%;
  /* display: -webkit-box;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none
  } */
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const TranslateString = useI18n()
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontSize="14px">Common Tokens</Text>
        <QuestionHelper text={TranslateString(1204, 'These tokens are commonly paired with other tokens.')} />
      </AutoRow>
      <Div>
        <Wrapper>
          <AutoRow gap="4px">
            <BaseWrapper
              onClick={() => {
                if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
                  onSelect(ETHER)
                }
              }}
              disable={selectedCurrency === ETHER}
            >
              <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
              <Text>{ETHER.symbol}</Text>
            </BaseWrapper>
            {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
              const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
              return (
                <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
                  <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
                  <Text>{token.symbol}</Text>
                </BaseWrapper>
              )
            })}
          </AutoRow>
        </Wrapper>
      </Div>
    </AutoColumn>
  )
}
