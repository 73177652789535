import { useMemo } from 'react'
import {
  getERC20Contract,
  getSouschefContract,
  getCakeVaultContract,
  getCakeContract,
  getIfoPoolContract,
  getMasterchefContract,
  getCreamFundContract,
  getCreamPresaleContract,
  getCreamGameContract,
  getFeeOnTransferDetectorContract,
} from 'utils/contractHelpers'
import { getCreamGameAddress, getFeeOnTransferDetectorAddress } from 'utils/addressHelpers'
import {
  CakeVault,
  CreamFund,
  CreamGame,
  CreamPresale,
  Erc20,
  FeeOnTransferDetector,
  IfoPool,
  Masterchef,
  Multicall,
  Weth,
} from 'config/abi/types'

import { VaultKey } from 'state/types'

import { useActiveWeb3React } from './index'

// Imports below migrated from Exchange useContract.ts
import { getProviderOrSigner } from '../utils'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20Contract = (address: string, withSignerIfPossible = true): Erc20 => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getERC20Contract(address, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, address, library, withSignerIfPossible]
  )
}

export const useMasterchef = (withSignerIfPossible = true): Masterchef => {
  const { library, account } = useActiveWeb3React()
  return useMemo(() => getMasterchefContract(withSignerIfPossible ? getProviderOrSigner(library, account) : library), [
    account,
    library,
    withSignerIfPossible,
  ])
}

export const useVaultPoolContract = (vaultKey: VaultKey): CakeVault | IfoPool => {
  const { library } = useActiveWeb3React()
  return useMemo(() => {
    return vaultKey === VaultKey.CakeVault
      ? getCakeVaultContract(library.getSigner())
      : getIfoPoolContract(library.getSigner())
  }, [library, vaultKey])
}

export const useSousChef = (id) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSouschefContract(id, library.getSigner()), [id, library])
}

export const useCakeVaultContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCakeVaultContract(library.getSigner()), [library])
}

export const useCake = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCakeContract(library.getSigner()), [library])
}

export function useCreamFundContract(): CreamFund {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(() => getCreamFundContract(chainId, library.getSigner()), [library, chainId])
}

export function useCreamPresaleContract(address?: string): CreamPresale {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCreamPresaleContract(address, library.getSigner()), [address, library])
}

export function useCreamGameContract(): CreamGame {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(() => getCreamGameContract(getCreamGameAddress(chainId), library.getSigner()), [chainId, library])
}

export function useFeeOnTransferDetectorContract(): FeeOnTransferDetector {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(
    () => getFeeOnTransferDetectorContract(getFeeOnTransferDetectorAddress(chainId), library.getSigner()),
    [chainId, library]
  )
}

export default {}
