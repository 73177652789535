import { useCallback, useContext } from 'react'
import { TranslationsContext } from 'hooks/TranslationsContext'
import { getTranslation } from 'utils/translateTextHelpers'

const useI18n = () => {
  const { translations } = useContext(TranslationsContext)

  /**
   * As a temporary fix memoize the translation function so it can be used in an effect.
   * It appears the TranslationsContext is always empty and is not currently used
   * TODO: Figure out if the context is used and if not, remove it.
   */
  return useCallback(
    (translationId: number, fallback: string) => {
      if (translations[0] === 'error') {
        return fallback
      }
      if (translations[fallback] === undefined) {
        return fallback
      }
      return translations[fallback]
      // if (translations.length > 0) {
      //   return getTranslation(translations, translationId, fallback)
      // }
      // return fallback
    },
    [translations]
  )
}
type ReactText = string | number
export type ContextData = {
  [key: string]: ReactText
}

export const useTranslation = () => {
  const { translations } = useContext(TranslationsContext)
  /**
   * As a temporary fix memoize the translation function so it can be used in an effect.
   * It appears the TranslationsContext is always empty and is not currently used
   * TODO: Figure out if the context is used and if not, remove it.
   */
  const t = useCallback(
    (key: string, data?: ContextData) => {
      const translationSet = translations
      const translatedText = translationSet[key] || key

      // Check the existence of at least one combination of %%, separated by 1 or more non space characters
      const includesVariable = translatedText.match(/%\S+?%/gm)

      if (includesVariable && data) {
        let interpolatedText = translatedText
        Object.keys(data).forEach((dataKey) => {
          const templateKey = new RegExp(`%${dataKey}%`, 'g')
          interpolatedText = interpolatedText.replace(templateKey, data[dataKey].toString())
        })

        return interpolatedText
      }

      return translatedText
    },
    [translations]
  )

  return {
    t,
  }
}

export const useI18nText = () => {
  const { translations } = useContext(TranslationsContext)
  /**
   * As a temporary fix memoize the translation function so it can be used in an effect.
   * It appears the TranslationsContext is always empty and is not currently used
   * TODO: Figure out if the context is used and if not, remove it.
   */
  return useCallback(
    (fallback: string, fallbackObject?: { [key: string]: string }) => {
      if (typeof translations === 'object') {
        if (translations[fallback] === undefined) {
          return fallback
        }
        const includesVariable = translations[fallback].includes('%')
        if (includesVariable) {
          // return replaceDynamicString(translations[fallback], fallbackObject)
        }
        return translations[fallback]
      }
      return fallback
    },
    [translations]
  )
}

const variableRegex = /%(.*?)%/

const replaceDynamicString = (foundTranslation: string, fallback: string) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stringToReplace = variableRegex.exec(foundTranslation)![0]
  const indexToReplace = foundTranslation.split(' ').indexOf(stringToReplace)
  const fallbackValueAtIndex = fallback.split(' ')[indexToReplace]
  return foundTranslation.replace(stringToReplace, fallbackValueAtIndex)
}

export default useI18n
