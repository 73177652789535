import axios from 'axios'
import * as Qs from 'qs'

const getParams = (params: any) => {
  return Qs.stringify(params, { indices: false })
}

const create = (baseURL: string, onFulfilled: any) => {
  axios.defaults.baseURL = baseURL
  axios.defaults.timeout = 15000

  axios.interceptors.request.use(onFulfilled, (error) => {
    Promise.reject(error).then((r) => r)
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      let err: any

      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        err = error.response.data
      } else if (error.request) {
        console.log(error.request)
        err = 'reuqest no response'
      } else {
        // console.log('Error', error.message);
        err = 'config error'
      }
      // console.log(error.config);
      return Promise.reject(err).then((r) => r)
    }
  )

  const get = (url: string, params?: any) =>
    axios({
      method: 'GET',
      url,
      headers: { accept: 'application/json' },
      params,
      paramsSerializer: getParams,
    })

  const delete0 = (url: string, params?: any) =>
    axios({
      method: 'DELETE',
      url,
      headers: { accept: 'application/json' },
      params,
      paramsSerializer: getParams,
    })

  const post = (url: string, body: any, params?: any) =>
    axios({
      method: 'POST',
      url,
      headers: { 'content-type': 'application/json', accept: 'application/json' , },
      data: body,
      params,
      paramsSerializer: getParams,
    })

  const put = (url: string, body: any, params?: any) =>
    axios({
      method: 'PUT',
      url,
      headers: { 'content-type': 'application/json', accept: 'application/json' , },
      data: body,
      params,
      paramsSerializer: getParams,
    })

  return { get, delete: delete0, post, put }
}

export default create
