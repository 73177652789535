import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M20.9951 6.90295C20.9789 6.73462 20.9203 6.57318 20.8246 6.43378C20.7289 6.29431 20.5993 6.18152 20.4481 6.10596L12.475 2.10596C12.3363 2.03589 12.1832 1.99927 12.0278 1.9989C11.8724 1.99854 11.7191 2.03455 11.58 2.104L3.55304 6.104C3.25604 6.25397 3.05103 6.54095 3.00903 6.87097C2.99603 6.96796 1.86401 16.612 11.55 21.879C11.6989 21.9602 11.8661 22.002 12.0357 22.0004C12.2053 21.9988 12.3717 21.9539 12.519 21.87C21.826 16.611 21.033 7.297 20.9951 6.90295ZM12.0181 19.847C5.15802 15.837 4.87805 9.495 4.95502 7.64197L12.0261 4.11896L19.024 7.63C19.0291 9.5 18.543 15.873 12.0181 19.847Z" />
    </Svg>
  )
}

export default Icon
