
/**
 * 修饰地址字符串，返回一个简短的地址
 */
const truncateHash = (address: string, startLength = 4, endLength = 4) => {
  return `${address.substring(0, startLength)}...${address.substring(
    address.length - endLength
  )}`
}


export default truncateHash

