import { Currency, CurrencyAmount, JSBI, Pair, Percent, Token, Trade, TradeType } from 'pancake-sdk'
import flatMap from 'lodash.flatmap'
import { useMemo } from 'react'
import chunkArray from 'utils/chunkArray'
import { BIPS_BASE } from 'config/constants'
import { BASES_TO_CHECK_TRADES_AGAINST, CUSTOM_BASES } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { wrappedCurrency } from '../utils/wrappedCurrency'

import { useActiveWeb3React } from './index'

let pairFilterMap: { [acc in string]: any } = {}
function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency, liquidity?: boolean): Pair[][] {
  const { chainId } = useActiveWeb3React()

  // Base tokens for building intermediary trading routes
  const bases: Token[] = useMemo(() => (chainId ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : []), [chainId])

  // All pairs from base tokens
  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])).filter(
        ([t0, t1]) => t0.address !== t1.address
      ),
    [bases]
  )

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            // This filter will remove all the pairs that are not supported by the CUSTOM_BASES settings
            // This option is currently not used on Pancake swap
            .filter(([t0, t1]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]
              if (!customBases) return true

              const customBasesA: Token[] | undefined = customBases[t0.address]
              const customBasesB: Token[] | undefined = customBases[t1.address]

              if (!customBasesA && !customBasesB) return true
              if (customBasesA && !customBasesA.find((base) => t1.equals(base))) return false
              if (customBasesB && !customBasesB.find((base) => t0.equals(base))) return false

              return true
            })
            .filter(([t0, t1]) => {
              if (pairFilterMap[`${t0.address}-${t1.address}`]) {
                return false
              }
              pairFilterMap[`${t0.address}-${t1.address}`] = [t0, t1]
              return true
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  )
  pairFilterMap = {}

  const results = usePairs(allPairCombinations, liquidity)
  const [size, allPairs] = results
  const chunkedPiars = useMemo(() => (size === 0 ? [] : [...chunkArray(allPairs, size), allPairs]), [size, allPairs])

  // only pass along valid pairs, non-duplicated pairs

  return useMemo(
    () =>
      chunkedPiars.map((pairs) => {
        return Object.values(
          pairs
            // filter out invalid pairs
            .filter((result): result is [PairState.EXISTS, Pair] =>
              Boolean(result[0] === PairState.EXISTS && result[1])
            )
            // filter out duplicated pairs
            .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
              memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
              return memo
            }, {})
        )
      }),
    [chunkedPiars]
  )
}

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
// export function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency): Trade | null {
//   const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)

//   return useMemo(() => {
//     if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
//       return (
//         Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 3, maxNumResults: 1 })[0] ?? null
//       )
//     }
//     return null
//   }, [allowedPairs, currencyAmountIn, currencyOut])
// }

const MAX_HOPS = 3

export function isTradeBetter(
  tradeA: Trade | undefined | null,
  tradeB: Trade | undefined | null,
  minimumDelta: Percent = new Percent('0')
): boolean | undefined {
  if (tradeA && !tradeB) return false
  if (tradeB && !tradeA) return true
  if (!tradeA || !tradeB) return undefined

  if (
    tradeA.tradeType !== tradeB.tradeType
    // !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    // !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable')
  }

  if (minimumDelta.equalTo(new Percent('0'))) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice)
  }
  return tradeA.executionPrice.asFraction.multiply(minimumDelta.add(new Percent('1'))).lessThan(tradeB.executionPrice)
}

export function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency): Trade | null {
  let allowedPairAlls = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)
  const [allowedPairs] = allowedPairAlls

  allowedPairAlls = useMemo(() => {
    const _allowedPairAlls = allowedPairAlls.length > 0 ? allowedPairAlls[allowedPairAlls.length - 1] : []

    return [Object.values(
      _allowedPairAlls .reduce<{ [pairAddress: string]: Pair }>((memo, pair) => {
        const _nextPair = memo[`${pair.token0.address}-${pair.token1.address}`]
        if (!_nextPair) {
          memo[`${pair.token0.address}-${pair.token1.address}`] = pair
          return memo
        }
        if (pair.reserve0.greaterThan(_nextPair.reserve0) && pair.reserve1.greaterThan(_nextPair.reserve1)) {
          memo[`${pair.token0.address}-${pair.token1.address}`] = pair
        }
        return memo
      }, {})
    )]
  }, [allowedPairAlls])

  allowedPairAlls[0]?.map((pair) =>
    console.log(
      `${pair.token0.address}-${
        pair.token1.address
      }--${pair.reserve0?.toSignificant()}-${pair.reserve1?.toSignificant()}`
    )
  )
  console.log('--')
  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairAlls.length > 0) {
      // if (singleHopOnly) {
      //   return (
      //     Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
      //     null
      //   )
      // }
      // search through trades with varying hops, find best trade out of them
      const bestTradeSoFarAll: any[] = []
      for (let size = 0; size < allowedPairAlls.length; size++) {
        let bestTradeSoFar: Trade | null = null
        for (let i = 1; i <= MAX_HOPS; i++) {
          if (allowedPairAlls[size].length === 0) break
          const currentTrade: Trade | null =
            Trade.bestTradeExactIn(allowedPairAlls[size], currencyAmountIn, currencyOut, {
              maxHops: i,
              maxNumResults: 1,
            })[0] ?? null
          // if current trade is best yet, save it

          if (isTradeBetter(bestTradeSoFar, currentTrade, new Percent(JSBI.BigInt(50), BIPS_BASE))) {
            bestTradeSoFar = currentTrade
          }
        }
        bestTradeSoFarAll.push(bestTradeSoFar)
      }
      let trade: Trade | null = bestTradeSoFarAll[0]
      for (let i = 1; i < bestTradeSoFarAll.length; i++) {
        // if current trade is best yet, save it
        if (isTradeBetter(trade, bestTradeSoFarAll[i])) {
          trade = bestTradeSoFarAll[i]
        }
      }
      return trade
    }

    return null
  }, [currencyAmountIn, currencyOut, allowedPairAlls])
}
/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount): Trade | null {
  let allowedPairAlls = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)
  const [allowedPairs] = allowedPairAlls

  allowedPairAlls = useMemo(() => {
    const _allowedPairAlls = allowedPairAlls.length > 0 ? allowedPairAlls[allowedPairAlls.length - 1] : []

    return [Object.values(
      _allowedPairAlls .reduce<{ [pairAddress: string]: Pair }>((memo, pair) => {
        const _nextPair = memo[`${pair.token0.address}-${pair.token1.address}`]
        if (!_nextPair) {
          memo[`${pair.token0.address}-${pair.token1.address}`] = pair
          return memo
        }
        if (pair.reserve0.greaterThan(_nextPair.reserve0) && pair.reserve1.greaterThan(_nextPair.reserve1)) {
          memo[`${pair.token0.address}-${pair.token1.address}`] = pair
        }
        return memo
      }, {})
    )]
  }, [allowedPairAlls])

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairAlls.length > 0) {
      const bestTradeSoFarAll: any[] = []
      for (let size = 0; size < allowedPairAlls.length; size++) {
        let bestTradeSoFar: Trade | null = null
        for (let i = 1; i <= MAX_HOPS; i++) {
          if (allowedPairAlls[size].length === 0) break
          const currentTrade: Trade | null =
            Trade.bestTradeExactOut(allowedPairAlls[size], currencyIn, currencyAmountOut, {
              maxHops: i,
              maxNumResults: 1,
            })[0] ?? null
          // if current trade is best yet, save it
          if (isTradeBetter(bestTradeSoFar, currentTrade, new Percent(JSBI.BigInt(50), BIPS_BASE))) {
            bestTradeSoFar = currentTrade
          }
        }
        bestTradeSoFarAll.push(bestTradeSoFar)
      }
      let trade: Trade | null = bestTradeSoFarAll[0]
      for (let i = 1; i < bestTradeSoFarAll.length; i++) {
        // if current trade is best yet, save it
        if (isTradeBetter(trade, bestTradeSoFarAll[i])) {
          trade = bestTradeSoFarAll[i]
        }
      }
      return trade && new Trade(trade?.route, trade?.inputAmount, TradeType.EXACT_INPUT)
      // return trade
    }
    return null
  }, [allowedPairAlls, currencyIn, currencyAmountOut])
}
