import styled from 'styled-components'

const Card = styled.div<any>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid #6f66f688; // ${({ theme }) => theme.colors.invertedContrast};
  /* background-color: ${({ theme }) => theme.colors.invertedContrast}; */
  /* background: linear-gradient(to right, #6f50d9, #bc5d9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`
