import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Flex, Input, Text, Toggle } from 'pancake-uikit'
import { useUserAutoSlippageEnabled, useUserSlippageTolerance } from 'state/user/hooks'

import { useGasPriceManager } from 'state/user/hooks/h-index'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/user/hooks/helpers'
import { parseUnits } from 'ethers/lib/utils'
import { BigNumber, utils } from 'ethers'
import QuestionHelper from '../QuestionHelper'

const MAX_SLIPPAGE = 10000
const RISKY_SLIPPAGE_LOW = 50
const RISKY_SLIPPAGE_HIGH = 500

const Option = styled.div`
  padding: 0 4px;
`

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${Option}:first-child {
    padding-left: 0;
  }

  ${Option}:last-child {
    padding-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const predefinedValues = [
  // { label: '0.1%', value: 0.1 },
  { label: '0.5%', value: 0.5 },
  { label: '1%', value: 1 },
]

const gasValues = [
  { label: 'default', value: parseUnits(GAS_PRICE.default, 'gwei').toString() },
  { label: 'fast(6)', value: parseUnits('6', 'gwei').toString() },
  // { label: 'instant(5)', value: 5 },
]

type SlippageToleranceSettingsModalProps = {
  translateString: (translationId: number, fallback: string) => string
}

const SlippageToleranceSettings = ({ translateString }: SlippageToleranceSettingsModalProps) => {
  const [userAutoSlippageEnabled, setUserAutoSlippageEnabled] = useUserAutoSlippageEnabled()
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [value, setValue] = useState(userSlippageTolerance / 100)
  const [gas, setGas] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setValue(parseFloat(inputValue))
  }

  const [gasPrice, setGasPrice] = useGasPriceManager()

  const [gasValue, setGasValue] = useState(BigNumber.from(gasPrice).div(utils.parseUnits('1', '9')).toString())

  useEffect(() => {
    setGasValue(BigNumber.from(gasPrice).div(utils.parseUnits('1', '9')).toString())
  }, [gasPrice])
  const gasHandleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setGasValue(inputValue)
    setGasPrice(utils.parseUnits(inputValue || '3', '9').toString())
  }
  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = value * 100
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue)
        setError(null)
      } else {
        setError(translateString(1144, 'Enter a valid slippage percentage'))
      }
    } catch {
      setError(translateString(1144, 'Enter a valid slippage percentage'))
    }
  }, [value, setError, setUserslippageTolerance, translateString])

  // Notify user if slippage is risky
  useEffect(() => {
    if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
      setError(translateString(1146, 'Your transaction may fail'))
    } else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
      setError(translateString(1148, 'Your transaction may be frontrun'))
    }
  }, [userSlippageTolerance, setGasPrice, setError, translateString])

  console.log(`userAutoSlippageEnabled: ${userAutoSlippageEnabled}`)
  return (
    <Box mb="16px">
      <Box style={{ marginBottom: '15px' }}>
        <Flex alignItems="center" mb="8px">
          <Text bold>{translateString(88, 'Default Transaction Speed (GWEI)')}</Text>
          <QuestionHelper
            text={translateString(
              186,
              'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees. Choose “Default” to use the settings from your current blockchain RPC node.'
            )}
          />
        </Flex>

        <Options>
          <Flex mb={['8px', '8px', 0]} mr={[0, 0, '8px']}>
            {gasValues.map(({ label, value: predefinedValue }) => {
              const handleClick = () => setGasPrice(predefinedValue)

              return (
                <Option key={predefinedValue}>
                  <Button variant={gasPrice === predefinedValue ? 'primary' : 'tertiary'} onClick={handleClick}>
                    {translateString(1, label)}
                  </Button>
                </Option>
              )
            })}
          </Flex>
          <Flex alignItems="center">
            <Option>
              <Input
                type="number"
                scale="lg"
                step={0.1}
                min={0.1}
                placeholder="3"
                value={gasValue}
                onChange={gasHandleChange}
              />
            </Option>
            <Option>
              <Text fontSize="18px"> gas</Text>
            </Option>
          </Flex>
        </Options>
      </Box>
      <Flex justifyContent="space-between" alignItems="center" mb="5px">
        <Flex alignItems="center">
          <Text bold>{translateString(1, 'Auto Slippage')}</Text>
          <QuestionHelper
            text={translateString(
              186,
              'Fee is applied on a few token pairs to ensure the best experience with CreamSwap. There is no fee associated with this swap.'
            )}
          />
        </Flex>
        <Toggle
          id="toggle-expert-mode-button"
          scale="md"
          checked={userAutoSlippageEnabled}
          onChange={setUserAutoSlippageEnabled}
        />
      </Flex>
      <Flex alignItems="center" mb="8px">
        <Text bold>{translateString(88, 'Slippage tolerance')}</Text>
        <QuestionHelper
          text={translateString(
            186,
            'Your transaction will revert if the price changes unfavorably by more than this percentage.'
          )}
        />
      </Flex>
      <Options>
        <Flex mb={['8px', '8px', 0]} mr={[0, 0, '8px']}>
          {predefinedValues.map(({ label, value: predefinedValue }) => {
            const handleClick = () => setValue(predefinedValue)

            return (
              <Option key={predefinedValue}>
                <Button variant={value === predefinedValue ? 'primary' : 'tertiary'} onClick={handleClick}>
                  {label}
                </Button>
              </Option>
            )
          })}
        </Flex>
        <Flex alignItems="center">
          <Option>
            <Input
              type="number"
              scale="lg"
              step={0.1}
              min={0.1}
              placeholder="5%"
              value={value}
              onChange={handleChange}
              isWarning={error !== null}
            />
          </Option>
          <Option>
            <Text fontSize="18px">%</Text>
          </Option>
        </Flex>
      </Options>

      {error && (
        <Text mt="8px" color="failure">
          {error}
        </Text>
      )}
    </Box>
  )
}

export default SlippageToleranceSettings
