import React, { Suspense, useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import type { CollapseProps } from 'antd'
import { Collapse, Avatar, message, Modal, Input } from 'antd'
import { Button, useMatchBreakpoints } from 'pancake-uikit'
import truncateHash from 'utils/truncateHash'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { GameNav } from 'components/CardNav'
import useI18n from 'hooks/useI18n'
import { useAppDispatch, useAppSelector } from 'state'
import { useActiveWeb3React } from 'hooks'
import { fetchGameDataAsync, fetchGameUserDataAsync, fetchListDataAsync, setCode } from 'state/game'
import { useCurrency } from 'hooks/Tokens'
import { useCreamGameContract } from 'hooks/useContractV2'
import { getCreamGameAddress, getCreamTokenAddress } from 'utils/addressHelpers'
import { CurrencyAmount, ETHER, TokenAmount } from 'pancake-sdk'
import { unwrappedToken, wrappedCurrency } from 'utils/wrappedCurrency'
import copy from 'copy-to-clipboard'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { utils } from 'ethers'
import { useParams } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { GameUsserInfo } from 'state/types'
import { Dots } from 'components/swap/styleds'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { useGameAll } from './hooks/hooks'

const GameContrainer = styled.div`
  width: 1080px;
  margin: 0px auto;
  box-sizing: border-box;
  margin-top: 50px;
  @media screen and (min-width: 360px) {
    width: 100%;
    padding: 0px 15px;
  }
  @media screen and (min-width: 576px) {
    width: 1080px;
    padding: 0px;
  }
  .content {
    border-radius: 20px;
    background: #fff;
    padding: 24px;
    margin-top: 30px;

    .introduce {
      margin: 20px 0px;
      h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      span {
        margin-top: 10px;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .ref {
      background-color: #f9f7f8;
      font-size: 14px;
      padding: 8px;
      display: flex;
      color: rgb(158, 158, 165);
      margin-bottom: 5px;
      > div {
        flex: 1;
        > span {
          font-size: 12px;
        }
        > button {
          font-size: 12px;
          float: right;
          padding: 0px 20px;
          margin-left: 5px;
          background: linear-gradient(90deg, #fcc545, #f99d38) !important;
          height: 28px !important;
        }
      }
      .text {
        white-space: nowrap; /* 禁止文本换行 */
        overflow: hidden; /* 隐藏溢出的内容 */
        text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
      }
      .shrea {
        text-align: center;
      }
    }
    .infoData {
      background-color: #f9f7f8;
      padding: 14px;
      margin-bottom: 30px;
      font-size: 15px;
      color: rgb(158, 158, 165);
      .shrea {
        line-height: 20px;
        span {
          font-size: 13px;
          float: right;
        }
      }
      .data {
        display: flex;
        > div:nth-child(1) {
          text-align: center;
          @media screen and (min-width: 360px) {
            width: 30%;
          }
          @media screen and (min-width: 576px) {
            width: 50%;
          }
        }
        > div:nth-child(2) {
          p {
            margin-right: '10px';
          }
          @media screen and (min-width: 360px) {
            width: 70%;
          }
          @media screen and (min-width: 576px) {
            width: 50%;
          }
        }
        > div {
          flex: 1;
          line-height: 20px;
        }
      }
    }
  }
  .ant-collapse-header {
    padding: 10px 16px !important;
  }
`

const LogoHeader = styled.div`
  width: 100%;
  @media screen and (min-width: 360px) {
    height: 150px;
  }
  @media screen and (min-width: 576px) {
    height: 200px;
  }
  background: linear-gradient(90deg, #7747fd, #6f66f6) !important;
  border-radius: 20px;
  position: relative;
  box-shadow: inset 0px 2px 2px -1px rgb(119 71 253 / 10%);
  .gx {
    position: absolute;
    height: 100%;
    @media screen and (min-width: 360px) {
      right: 10%;
      width: auto;
    }
    @media screen and (min-width: 576px) {
      right: 100px;
      width: 160px;
    }
  }
  .cw {
    position: absolute;
    height: 140%;
    top: -33px;
    @media screen and (min-width: 360px) {
      top: -33px;
      left: 10px;
    }
    @media screen and (min-width: 576px) {
      top: -42px;
      left: 30px;
    }
  }
  .wb {
    z-index: 100;
    width: 60%;
    position: absolute;
    right: 0px;
    color: #fff;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (min-width: 360px) {
      font-size: 14px;
    }
    @media screen and (min-width: 576px) {
      font-size: 18px;
    }

    .title {
      font-weight: 600;
      margin-bottom: 10px;
      @media screen and (min-width: 360px) {
        font-size: 18px;
      }
      @media screen and (min-width: 576px) {
        font-size: 36px;
      }
    }
    button {
      @media screen and (min-width: 360px) {
        margin-top: 0px;
      }
      @media screen and (min-width: 576px) {
        margin-top: 10px;
      }
      padding: 0px 20px;
      margin-left: 5px;
      background: linear-gradient(90deg, #fcc545, #f99d38) !important;
      height: 28px !important;
    }
  }
`

const LevelCollapseList = styled.div``

const LevelCollapse = styled(Collapse)`
  border: 1px solid #c3c3c6;
  margin-top: 10px;
  border-radius: 15px;
  background: #f3f3f3;
  box-shadow: 0px 3px 3px 0px rgba(25, 26, 34);
  .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content-box {
    background-color: #f9f7f8;
    border-radius: 15px;
  }
  .ant-collapse-content {
    background: none;
  }
  .ant-collapse-header-text {
    color: #000;
    font-weight: 600;
  }
  .ant-collapse-content {
    max-height: 300px;
    overflow: auto;
  }
  svg {
    fill: #86868b;
  }
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: none;
  }
`

const CollapseRowDiv = styled.div`
  display: 'flex';
  > div {
    font-family: 'fangsong' !important;
    display: inline-block;
    width: 25%;
  }
  > div:nth-child(1) {
    width: 50%;
  }
`
const IdoInput = styled(Input)`
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
  border: 1px solid #f9f7f8 !important;
  height: 80px;
  position: relative;
  margin-top: 10px;
  text-align: center;
  background: #f9f7f8;
  :hover {
    border: 1px solid rgb(77, 84, 255) !important;
    box-shadow: 0 0 0 2px rgb(236 230 194 / 10%);
  }
  .ant-input-suffix {
    color: rgb(77, 84, 255);
    font-weight: 600;
  }
  span > div {
    cursor: pointer;
  }
  .ant-input {
    background: #f9f7f8;
    height: 38px;
    border: 1px solid #f9f7f8;
    border-radius: 0px !important;
    border-right: none;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-top: 20px;
    color: #00000088;
    ::placeholder {
      color: #00000088;
    }
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%); */
    /* :hover {
      border: 1px solid rgb(77, 84, 255);
      border-right: none;
      box-shadow: 0 0 0 2px rgb(236 230 194 / 10%);
    } */
  }

  .ant-input-group-addon {
    border: 1px solid #ece6c2;
    background: none;
    color: rgb(77, 84, 255);
    cursor: pointer;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    :hover {
      border: 1px solid rgb(77, 84, 255);
      border-right: none;
      box-shadow: 0 0 0 2px rgb(236 230 194 / 10%);
    }
  }
  /* box-shadow: 0 0 0 2px rgb(236 230 194 / 10%); */
`

const CollapseRow: React.FC<React.PropsWithChildren<{ level: number; address: string; referrals: number }>> = ({
  address,
  level,
  referrals,
}) => {
  const TranslateString = useI18n()
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <CollapseRowDiv>
      <div style={{ flex: '1' }}>{!isMobile ? address : truncateHash(address)}</div>
      <div style={{ flex: '1' }}>{`V${level}`}</div>
      <div style={{ flex: '1' }}>
        {TranslateString(1, 'Direct push')} {referrals}{' '}
      </div>
    </CollapseRowDiv>
  )
}

const CollapseList: React.FC<React.PropsWithChildren<{ list: GameUsserInfo[][] }>> = ({ list }) => {
  const dataList = [[], [], [], [], [], [], []]
  const TranslateString = useI18n()
  const result = (list || dataList).map((data, index) => {
    const items: CollapseProps['items'] = [
      {
        key: '1',
        label: `${index + 1} ${TranslateString(1, 'Layer Users')}: `,
        children: data.map(({ account, rank_level, referrals }) => {
          return <CollapseRow address={account} level={rank_level} referrals={referrals} />
        }),
        // extra: genExtra(),
      },
    ]
    return <LevelCollapse expandIconPosition="end" items={items} />
  })

  return <>{result}</>
}

const upgradeFee = [5000, 7000, 15000, 30000, 60000, 120000, 240000]

export const GamePage = () => {
  const params = useParams()
  const TranslateString = useI18n()
  const dispatch = useAppDispatch()
  const { chainId, account } = useActiveWeb3React()
  const store = useAppSelector((state) => state.game)
  const [nav, setNav] = useState(0)
  const [refCdoe, setRefCode] = useState('')
  const gameContract = useCreamGameContract()
  const _code = params[0]

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  useEffect(() => {
    const initCode = async () => {
      const user = await gameContract.users(_code)
      if (Number(user.code.toString()) > 0) {
        dispatch(setCode(Number(user.code.toString())))
      }
    }
    if (utils.isAddress(_code)) {
      initCode()
    }
  }, [dispatch, gameContract, _code])

  const currency = wrappedCurrency(useCurrency(getCreamTokenAddress(chainId)), chainId)
  const MainUserInfo = useMemo(() => {
    return {
      ...store.userInfo,
      referralReward: currency && new TokenAmount(currency, store?.userInfo?.referralReward || '0'),
      levelReward: currency && new TokenAmount(currency, store?.userInfo?.levelReward || '0'),
      cumulativeDividends: CurrencyAmount.fromRawAmount(ETHER, store?.userInfo?.cumulativeDividends || '0'),
      totalRealised: CurrencyAmount.fromRawAmount(ETHER, store?.userInfo?.totalRealised || '0'),
    }
  }, [store, currency])
  useEffect(() => {
    dispatch(fetchGameDataAsync({ chainId }))
    if (account) {
      dispatch(fetchListDataAsync({ chainId, account }))
      dispatch(fetchGameUserDataAsync({ chainId, account }))
    }
  }, [dispatch, chainId, account])

  const [approvalA, approveACallback] = useApproveCallback(
    currency && new TokenAmount(currency, utils.parseEther('4000').toString()),
    getCreamGameAddress(chainId)
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [reqCode, setReqCode] = useState(false)

  const getCode = useCallback(
    async (ref: string) => {
      setReqCode(true)
      const user = await gameContract.users(ref)
      if (Number(user.code.toString()) >= 8888) {
        dispatch(setCode(Number(user.code.toString())))
        setIsModalOpen(false)
        message.success(TranslateString(1, 'Can be adopted'))
      }
      setReqCode(false)
    },
    [gameContract, TranslateString, dispatch]
  )

  const codeError = useMemo(() => {
    if (refCdoe === '') return ''
    if (!utils.isAddress(refCdoe)) return TranslateString(1, 'Invalid address')
    if (reqCode) return TranslateString(1, 'Verifying')
    // if (Number(refCdoe) < 8888 || Number(refCdoe) > store?.code || Number(refCdoe) % 8 !== 0) return '无效的 Code'
    return ''
  }, [refCdoe, TranslateString, reqCode])

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency && unwrappedToken(currency))

  const error = useMemo(() => {
    if (MainUserInfo.rank_level === 7) return ''

    const fee = !MainUserInfo.isRegister ? upgradeFee[0] : upgradeFee[MainUserInfo.rank_level]
    // if (!MainUserInfo.isRegister && !(store.codeRef % 8888 === 0)) return '没有推荐码'

    if (Number(selectedCurrencyBalance?.toSignificant()) < fee)
      return TranslateString(1, 'Insufficient balance to upgrade')
    return ''
  }, [selectedCurrencyBalance, TranslateString, MainUserInfo])

  const text = !MainUserInfo.isRegister
    ? TranslateString(1164, 'Adoption')
    : MainUserInfo?.rank_level === 7
    ? `${TranslateString(1164, 'Full level')}`
    : `${TranslateString(1164, 'upgrade')}${TranslateString(1, 'Drain')}: ${upgradeFee[MainUserInfo.rank_level]}`

  const shreaError = useMemo(() => {
    if (MainUserInfo.rank_level < 7) return TranslateString(1, 'Under level 7')
    if (!MainUserInfo.cumulativeDividends.greaterThan('0')) return TranslateString(1, 'Dividend 0')
    return ''
  }, [MainUserInfo, TranslateString])

  const refLike = !isMobile
    ? `https://creamswap.app/#/game/${MainUserInfo.account || ''}`
    : `https://creamswap.app/#/game/${(MainUserInfo.account && truncateHash(MainUserInfo.account)) || ''}`
  const {
    reqLoaderRegister,
    reqLoaderUpgrade,
    reqLoaderClaimLp,
    registerCallback,
    upgradeCallback,
    claimEndCallback,
  } = useGameAll({ code: store.codeRef, rank_level: MainUserInfo?.rank_level + 1 })
  return (
    <GameContrainer>
      <Modal
        title="Ref Code"
        centered
        open={isModalOpen}
        footer={Boolean('false')}
        onOk={() => {
          setIsModalOpen(false)
        }}
        onCancel={() => {
          setIsModalOpen(false)
        }}
      >
        <IdoInput value={refCdoe} onChange={(e) => setRefCode(e.target.value)} />
        <Button
          disabled={!!codeError}
          style={{ height: '42px', marginTop: '20px', width: '100%', borderRadius: '22px' }}
          onClick={() => getCode(refCdoe)}
        >
          {reqCode ? (
            <Dots> {TranslateString(1164, codeError || 'binding')}</Dots>
          ) : (
            TranslateString(1164, codeError || 'binding')
          )}
        </Button>
      </Modal>
      <LogoHeader>
        <img className="gx" src="./gx.png" alt="" />
        <img className="cw" src="./cw.png" alt="" />
        <div className="wb">
          <div>
            <p className="title">{TranslateString(1164, 'Cream Keeper')} </p>
            <p>{TranslateString(1164, 'Upgrade your Guardian Beast to get more $Cream!')}</p>
            <p>
              {TranslateString(1164, 'Your Level')}
              <Button>{MainUserInfo?.isRegister ? `V${MainUserInfo?.rank_level || 0}` : 'Live'}</Button>
            </p>
          </div>
        </div>
      </LogoHeader>
      <div className="content">
        <GameNav activeIndex={nav} setActiveIndex={setNav} />
        {nav === 0 ? (
          <>
            {MainUserInfo.isRegister && (
              <div className="ref">
                <div className="text">
                  <p>{TranslateString(1164, 'Referral links')}</p>
                  <span>{refLike}</span>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      copy(`https://creamswap.app/#/game/${MainUserInfo.account || ''}`)
                      message.success('copy success')
                    }}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            )}
            <div className="infoData">
              <div className="data">
                <div>
                  <p>{TranslateString(1164, 'Number referrals')}</p>
                  <span>{MainUserInfo?.referrals || 0}</span>
                </div>
                <div>
                  <p>
                    {TranslateString(1164, 'Grade return')}: {MainUserInfo?.levelReward?.toSignificant() || 0}{' '}
                  </p>
                  <p>
                    {TranslateString(1164, 'Referrals return')}: {MainUserInfo?.referralReward?.toSignificant() || 0}{' '}
                  </p>
                </div>
              </div>
            </div>

            {!account ? (
              <ConnectWalletButton
                width="100%"
                style={{ height: '42px', marginBottom: '20px', borderRadius: '22px' }}
              />
            ) : (
              <AutoColumn gap="md">
                {approvalA !== ApprovalState.APPROVED ? (
                  <RowBetween>
                    <Button
                      onClick={() => {
                        try {
                          approveACallback()
                          // message.success(`approval success`)
                        } catch (e) {
                          console.log(e)
                        }
                      }}
                      disabled={approvalA === ApprovalState.PENDING}
                      style={{ width: '100%', height: '42px', marginBottom: '20px', borderRadius: '22px' }}
                    >
                      {approvalA === ApprovalState.PENDING ? (
                        <Dots>Approving {currency?.symbol}</Dots>
                      ) : (
                        `${TranslateString(1, 'Approve')} ${currency?.symbol}`
                      )}
                    </Button>
                  </RowBetween>
                ) : (
                  <Button
                    disabled={!!error || reqLoaderRegister || reqLoaderUpgrade}
                    style={{ height: '42px', marginBottom: '20px', borderRadius: '22px' }}
                    onClick={() => {
                      if (!MainUserInfo.isRegister) {
                        if (!store.codeRef || store.codeRef % 8 !== 0) {
                          setIsModalOpen(true)
                        } else {
                          registerCallback()
                        }
                      } else {
                        if (MainUserInfo.rank_level === 7) return
                        upgradeCallback()
                      }
                    }}
                  >
                    {reqLoaderRegister || reqLoaderUpgrade ? (
                      <Dots> {TranslateString(1164, text)}</Dots>
                    ) : (
                      TranslateString(1164, error || text)
                    )}
                  </Button>
                )}
              </AutoColumn>
            )}
            <CollapseList list={store.hierarchyList} />
            <div className="introduce">
              <h1>{TranslateString(1164, 'Introduction to gameplay')}:</h1>

              <span>
                {TranslateString(1164, 'How to play: You only need 5,000 Cream to adopt your Cream Guardian;')} <br />
                {TranslateString(1164, 'Start by recommending 2 people to adopt Cream Guardians and build your team!')}
                <br />
                {TranslateString(1164, 'The maximum reward for upgrade income is 32,764,000 Cream;')}
                <br />
                {TranslateString(
                  1164,
                  'The direct push reward is up to 48,000 Cream per person, and there is no upper limit for the number of direct pushers;'
                )}
                <br />
                {TranslateString(
                  1164,
                  'Reaching level seven: permanently enjoy 30% of Swap profits as handling fee dividends!'
                )}
                <br />
              </span>
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className="ref">
              <div className="shrea">
                <p>{TranslateString(1, 'Global total dividend')}</p>
                <span>{CurrencyAmount.fromRawAmount(ETHER, store?.totalShares || '0')?.toSignificant()} BNB</span>
              </div>
            </div>
            <div className="infoData">
              <div className="shrea">
                <div>
                  <p>
                    {TranslateString(1, 'Earned dividends')}:{' '}
                    <span>{MainUserInfo?.totalRealised?.toSignificant()} BNB</span>{' '}
                  </p>
                  <p>
                    {TranslateString(1, 'Dividends claimed')}:{' '}
                    <span>{MainUserInfo?.cumulativeDividends?.toSignificant()} BNB</span>{' '}
                  </p>
                </div>
              </div>
            </div>
            <Button
              disabled={!!shreaError || reqLoaderClaimLp}
              onClick={claimEndCallback}
              style={{ height: '40px', borderRadius: '20px', width: '100%', marginBottom: '20px' }}
            >
              {reqLoaderClaimLp ? (
                <Dots> {TranslateString(1164, 'Claim dividends')}</Dots>
              ) : (
                TranslateString(1164, shreaError || 'Claim dividends')
              )}
            </Button>
          </>
        )}
      </div>
    </GameContrainer>
  )
}

export default GamePage
