import multicall, { multicallV3 } from 'utils/multicall'
import creamGameAbi from 'config/abi/creamGame.json'
import { getCreamGameAddress, getCreamPresaleListAddress } from 'utils/addressHelpers'
import { GameUsserInfo } from 'state/types'
import { ethers } from 'ethers'

export const fetchGameUserDataState = async (chainId: number, account: string): Promise<GameUsserInfo> => {
  const gameCall = [
    { address: getCreamGameAddress(chainId), name: 'userDatas', params: [account] },
    { address: getCreamGameAddress(chainId), name: 'users', params: [account] },
    { address: getCreamGameAddress(chainId), name: 'shares', params: [account] },
    { address: getCreamGameAddress(chainId), name: 'getUnpaidEarnings', params: [account] },
  ]
  const restCreamGame = await multicallV3<any>(chainId, creamGameAbi, gameCall)
  const [userDatas, users, shares, cumulativeDividends] = restCreamGame

  const gameUsserInfo: GameUsserInfo = {
    account: users.account,
    rank: Number(users.rank.toString()),
    rank_level: Number(users.rank_level.toString()),
    upper_referrer: users.upper_referrer,
    ref: users.referrer,
    code: Number(users.code.toString()),
    isRegister: !(users.account === ethers.constants.AddressZero),
    totalRealised: shares?.totalRealised.toString(),
    cumulativeDividends: cumulativeDividends?.toString(),
    referralReward: userDatas.referralReward.toString(),
    levelReward: userDatas.levelReward.toString(),
    referrals: Number(userDatas.referrals.toString()),
  }
  return gameUsserInfo
}

export const fetchGameDataState = async (chainId: number): Promise<any> => {
  const gameCall = [
    { address: getCreamGameAddress(chainId), name: 'RANK', params: [] },
    { address: getCreamGameAddress(chainId), name: 'WholeNetwork', params: [] },
    { address: getCreamGameAddress(chainId), name: 'totalDividends', params: [] },
    { address: getCreamGameAddress(chainId), name: 'CODE', params: [] },
  ]
  const restCreamGame = await multicallV3<any>(chainId, creamGameAbi, gameCall)
  const [RANK, WholeNetwork, totalShares, CODE] = restCreamGame

  const gameUsserInfo = {
    rank: Number(RANK.toString()),
    wholeNetwork: Number(WholeNetwork.toString()),
    totalShares: totalShares.toString(),
    code: Number(CODE.toString()),
  }
  return gameUsserInfo
}

export const fetchHierarchyListDataState = async (chainId: number, account: string): Promise<GameUsserInfo[][]> => {
  const gameCall = [
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 1] },
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 2] },
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 3] },
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 4] },
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 5] },
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 6] },
    { address: getCreamGameAddress(chainId), name: 'findAndPlaceUserStatus', params: [account, 7] },
  ]

  const restCreamGame = await multicallV3<any>(chainId, creamGameAbi, gameCall)

  const gameUsserInfosList = restCreamGame.map(([users, userDatas]) => {
    return users.map((user, index) => {
      return {
        account: user.account,
        rank: Number(user.rank.toString()),
        rank_level: Number(user.rank_level.toString()),
        upper_referrer: user.upper_referrer,
        ref: user.referrer,
        code: Number(user.code.toString()),
        isRegister: !(user.account === ethers.constants.AddressZero),
        referralReward: userDatas[index].referralReward.toString(),
        levelReward: userDatas[index].levelReward.toString(),
        referrals: Number(userDatas[index].referrals.toString()),
      }
    })
  })
  return gameUsserInfosList
}

export default {}
