import { VaultKey } from 'state/types'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens() as any
console.log(serializedTokens)
export const vaultPoolConfig = {
  [VaultKey.CakeVault]: {
    name: 'Auto CAKE',
    description: 'Automatic restaking',
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO CAKE',
    description: 'Stake CAKE to participate in IFOs',
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
      secondarySrc: `/images/tokens/${tokens.cake.address}.svg`,
    },
  },
} as const

const perBlock = '0.48225308641975306'

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0, // 前端排序的序号
    stakingToken: serializedTokens.cake, // 质押的 LP 地址
    earningToken: serializedTokens.cake, // 奖励的 Token 地址
    contractAddress: {
      // 矿池的地址
      97: '0x7296533769AbD68f704eB56354EAb4AB1354D74a', // masterChef 池子的合约
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE, // 池子的类型
    harvest: true, // 是否开放
    tokenPerBlock: '0', // 一个区块奖励多少 earningToken
    sortOrder: 1, // 页面排序权重
    isFinished: false, // 池子是否已经结束
  },
  {
    sousId: 1, // 前端排序的序号
    stakingToken: serializedTokens.usdt, // 质押的 LP 地址
    earningToken: serializedTokens.sloth, // 奖励的 Token 地址
    contractAddress: {
      // 矿池的地址
      97: '0x084cf35b79c9F554d1fD05f21dC2D3AE9b855e8E', // masterChef 池子的合约
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE, // 池子的类型
    harvest: true, // 是否开放
    tokenPerBlock: perBlock, // 一个区块奖励多少 earningToken
    sortOrder: 1, // 页面排序权重
    isFinished: false, // 池子是否已经结束
    stakingTokenPrice: 1,
    earningTokenPrice: 0.1,
  },
  {
    sousId: 2, // 前端排序的序号
    stakingToken: serializedTokens.usdc, // 质押的 LP 地址
    earningToken: serializedTokens.sloth, // 奖励的 Token 地址
    contractAddress: {
      // 矿池的地址
      97: '0x484B9140bdb4C9D5B1F77F857f3Ae825910A8eA8', // masterChef 池子的合约
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE, // 池子的类型
    harvest: true, // 是否开放
    tokenPerBlock: perBlock, // 一个区块奖励多少 earningToken
    sortOrder: 1, // 页面排序权重
    isFinished: false, // 池子是否已经结束
  },
  {
    sousId: 3, // 前端排序的序号
    stakingToken: serializedTokens.weth, // 质押的 LP 地址
    earningToken: serializedTokens.sloth, // 奖励的 Token 地址
    contractAddress: {
      // 矿池的地址
      97: '0x5b0Af9299AC44A726D29bB1A5F4dcAF80643ffDb', // masterChef 池子的合约
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE, // 池子的类型
    harvest: true, // 是否开放
    tokenPerBlock: perBlock, // 一个区块奖励多少 earningToken
    sortOrder: 1, // 页面排序权重
    isFinished: false, // 池子是否已经结束
  },
]

export default pools
