import { useEffect, useState , useRef} from 'react'
import { ChainId, Percent, Token, Trade } from 'pancake-sdk'
import { FeeOnTransferDetector } from 'config/abi/types'
import { BIPS_BASE, ZERO_PERCENT } from 'config/constants'
import {currencyId} from 'utils/currencyId'
import { useFeeOnTransferDetectorContract } from './useContractV2'
import useActiveWeb3React from './useActiveWeb3React'
import { BASES_TO_CHECK_TRADES_AGAINST } from '../constants'

const FEE_CACHE: { [address in string]?: { sellTax?: Percent; buyTax?: Percent } } = {}
const AMOUNT_TO_BORROW = 10000

function isBaseToken(chainId: ChainId, address: string): boolean {
  return BASES_TO_CHECK_TRADES_AGAINST[chainId].some((baseToken) => baseToken.address === address)
}

async function getSwapTaxes(chainId: ChainId, fotDetector: FeeOnTransferDetector, trade?: Trade) {
  const factorys = []
  const addresses = []
  const baseTokens = []
  let baseTokenAddress = ''
  if (trade) {
    // const inputAddress = wrappedCurrency(trade?.inputAmount.currency, chainId)?.address
    // if (isBaseToken(chainId, inputAddress)) {
    //   addresses.push(inputAddress)
    // }
    // const outputAddress = wrappedCurrency(trade?.outputAmount.currency, chainId)?.address
    // if (isBaseToken(chainId, outputAddress)) {
    //   addresses.push(outputAddress)
    // }
    trade?.route.pairs.map((pair) => {

      if(isBaseToken(chainId, pair.token0.address) && isBaseToken(chainId, pair.token1.address)){
        return undefined;
      }
      factorys.push(pair.factory)
      if(!isBaseToken(chainId, pair.token0.address)){
        addresses.push(pair.token0.address)
        baseTokenAddress = pair.token1.address
      }else{
        addresses.push(pair.token1.address)
        baseTokenAddress = pair.token0.address
      }
      return undefined
    })

    // trade?.route.pairs.map((pair) => {

    //   if(isBaseToken(chainId, pair.token0.address) && isBaseToken(chainId, pair.token1.address)){
    //     return undefined;
    //   }
    //   factorys.push(pair.factory)
    //   if(!isBaseToken(chainId, pair.token0.address)){
    //     addresses.push(pair.token0.address)
    //     baseTokens.push(pair.token1.address)
    //   }else{
    //     addresses.push(pair.token1.address)
    //     baseTokens.push(pair.token0.address)
    //   }
    //   return undefined
    // })
  }




  try {
    if (addresses.length) {

      const data = await fotDetector.callStatic.batchFactoryValidate(factorys, addresses, baseTokenAddress , AMOUNT_TO_BORROW)

      addresses.forEach((address, index) => {
        const { sellFeeBps, buyFeeBps } = data[index]
        const sellTax = new Percent(sellFeeBps.toNumber(), BIPS_BASE)
        const buyTax = new Percent(buyFeeBps.toNumber(), BIPS_BASE)

        FEE_CACHE[address] = { sellTax, buyTax }
      })
      console.log(FEE_CACHE)
    }
  } catch (e) {
    console.warn('Failed to get swap taxes for token(s):', addresses, e)
  }

  const inputTax =
    (trade?.inputAmount.currency instanceof Token
      ? FEE_CACHE[trade?.inputAmount.currency.address]?.sellTax
      : ZERO_PERCENT) ?? ZERO_PERCENT
  const outputTax =
    (trade?.outputAmount.currency instanceof Token
      ? FEE_CACHE[trade?.outputAmount.currency.address]?.buyTax
      : ZERO_PERCENT) ?? ZERO_PERCENT

  return { inputTax, outputTax }
}

export default function useSwapTaxes(trade?: Trade) {
  const fotDetector = useFeeOnTransferDetectorContract()
  const txsAddresses = useRef<{ inputAddress: string, outputAddress: string }>({ inputAddress: undefined, outputAddress: undefined })
  const [{ inputTax, outputTax }, setTaxes] = useState({ inputTax: ZERO_PERCENT, outputTax: ZERO_PERCENT })
  const { chainId } = useActiveWeb3React()

  const {inputAddress, outputAddress} = txsAddresses.current;

  useEffect(() => {
    if (!fotDetector || chainId !== ChainId.MAINNET || !trade || ((currencyId(trade?.inputAmount.currency) === inputAddress && currencyId(trade?.outputAmount.currency) === outputAddress ))) return

    console.log('...............................................')
    getSwapTaxes(chainId, fotDetector, trade).then(setTaxes)
    txsAddresses.current = {
      inputAddress: trade && currencyId(trade?.inputAmount.currency),
      outputAddress: trade &&  currencyId(trade?.outputAmount.currency)
    }
  }, [fotDetector, trade, inputAddress,outputAddress, chainId])

  if(!trade){
    return { inputTax: ZERO_PERCENT, outputTax: ZERO_PERCENT }
  }
  return { inputTax, outputTax }

}
