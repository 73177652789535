export default {
  masterChef: {
    97: '0x7296533769AbD68f704eB56354EAb4AB1354D74a',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    66: '0xD42c5077E6F6baCb3e5031b47e452628c36dF636',
  },

  multicall: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    66: '0x370aD3199b09c593f94d27abBcC9aa6022CC165b',
  },
  ifoPool: {
    56: '0x1B2A2f6ed4A1401E8C73B4c2B6172455ce2f78E8',
    97: '',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  creamFund: {
    56: '0x207935b78d8170b2adc65223990eb1e07e0e03de',
    97: '',
    66: '0x4BceEC121dE6D46fcB2E6C746829F206607CB7B1',
  },
  creamToken: {
    56: '0xD4ABef1D6B87a584888ec15B92EF69B07DA19F61', // '0xD4ABef1D6B87a584888ec15B92EF69B07DA19F61',
    97: '',
    66: '0x7173e6AB285a29E3CF48dfc8818aea91EA313559',
  },
  CreamPresaleList: {
    56: '0xBB46F64140C8B119C27589F2E05eeB0f5ADdD1c7',
    97: '',
    66: '0x1D67b203fe1F6697F45B034139aeFA195b84dBF1',
  },
  creamGame: {
    56: '0x17a47dA0000a4520e701c238565b830B137EF538',
    97: '',
    66: '0x9B9ae18D976554676f3d9f80b72a553944E74B57',
  },
  feeOnTransferDetector: {
    56: '0x54A3E36010dEeFe9025CCc16569EE187D9929482',
    97: '',
    66: '0x9B9ae18D976554676f3d9f80b72a553944E74B57',
  },
}
