import React, { useState } from 'react'
import { Flex, Modal, Text, Toggle } from 'pancake-uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useExpertModeManager, useUserExpertModeAcknowledgementShow } from 'state/user/hooks/h-index'
import { useSwapActionHandlers } from 'state/swap/hooks'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'
import ExpertModal from './ExpertModal'

type SettingsModalProps = {
  onDismiss?: () => void
  translateString: (translationId: number, fallback: string) => string
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal = ({ onDismiss = defaultOnDismiss, translateString }: SettingsModalProps) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const [showExpertModeAcknowledgement, setShowExpertModeAcknowledgement] = useUserExpertModeAcknowledgementShow()
  const { onChangeRecipient } = useSwapActionHandlers()

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        translateString={translateString}
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      />
    )
  }

  const handleExpertModeToggle = () => {
    if (expertMode) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else if (!showExpertModeAcknowledgement) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else {
      setShowConfirmExpertModal(true)
    }
  }

  return (
    <Modal title={translateString(1200, 'Settings')} onDismiss={onDismiss}>
      <SlippageToleranceSetting translateString={translateString} />
      <TransactionDeadlineSetting translateString={translateString} />
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Flex alignItems="center">
          <Text bold>{translateString(1, 'Expert Mode')}</Text>
          <QuestionHelper
            text={translateString(
              186,
              'Bypasses confirmation modals and allows high slippage trades. Use at your own risk.'
            )}
          />
        </Flex>
        <Toggle id="toggle-expert-mode-button" scale="md" checked={expertMode} onChange={handleExpertModeToggle} />
      </Flex>
    </Modal>
  )
}

export default SettingsModal
