import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

/**
 * 配置 farm 奖励池从参数
 * 必须是要配置这三个池子，不然年华就不会显示
 * */
const farms: SerializedFarmConfig[] = [
  {
    pid: 0, // 这个对应在 masterChef 矿池的下标
    lpSymbol: 'CAKE', // 前端显示的参数
    lpAddresses: {
      97: '0x28C9fF43aDd8b4C1289b81767E10374E20BA86a2', // LP 地址
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    token: serializedTokens.syrup, // 普通 Token
    quoteToken: serializedTokens.wbnb, // 计价 Token
  },
  {
    pid: 1,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '0x04f46b4b7b3561dfd7bf603287ef357642385ba1',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x3a9506e5257caf8b0f34333819ade44d0a1e170f',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
]

export default farms
