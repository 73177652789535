import { MenuEntry } from 'pancake-uikit'

/* eslint-disable */
const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'Burn Fund',
    icon: 'SunIcon',
    href: '/burnFund',
  },
  {
    label: 'Presale',
    icon: 'InfoIcon',
    href: '/presale',
  },
  {
    label: 'Game',
    icon: 'JoystickIcon',
    href: '/game',
  },
  {
    label: 'Audit',
    icon: 'BxShieldIcon',

    items: [
      {
        label: 'CreamFactory',
        href:
          'https://github.com/CFG-NINJA/audits/blob/e2db335618e4293653d5157fb66c9219ec2782e3/20231006_CFGNINJA_CreamFactory_Cream-LP_Audit.pdf',
      },
      {
        label: 'CreamFeeRouter',
        href:
          'https://github.com/CFG-NINJA/audits/blob/e2db335618e4293653d5157fb66c9219ec2782e3/20231006_CFGNINJA_CreamFeeRouter_Cream-LP_Audit.pdf',
      },
    ],
  },
  {
    label: 'Info',
    icon: 'NftIcon',
    href: 'https://info.creamswap.app/',
  },
]

export default config
