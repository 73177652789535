import multicall, { multicallV3 } from 'utils/multicall'
import creamPresaleAbi from 'config/abi/creamPresale.json'
import orderBy from 'lodash/orderBy'
import creamPresaleListAbi from 'config/abi/creamPresaleList.json'
import { ERC20_ABI } from 'constants/abis/erc20'
import { Token, TokenAmount } from 'pancake-sdk'
import { PresaleState, UserInfo } from 'state/types'
import { getCreamPresaleListAddress } from 'utils/addressHelpers'
import { retry } from 'utils/retry'

export const fetchPresaleState = async (chainId: number, presaleAddress: string): Promise<any> => {
  const presaleCall = [
    { address: presaleAddress, name: 'restState', params: [] },
    { address: presaleAddress, name: 'cpresale', params: [1] },
    { address: presaleAddress, name: 'cpresale', params: [2] },
  ]
  const restCreamPresale = await multicallV3<any>(chainId, creamPresaleAbi, presaleCall)
  const [presaleState, cpresale1, cpresale2] = restCreamPresale

  const tokenCall = [
    { address: presaleState[0].EthCurrency, name: 'decimals', params: [] },
    { address: presaleState[0].EthCurrency, name: 'symbol', params: [] },
    { address: presaleState[0].EthCurrency, name: 'name', params: [] },
  ]
  const restERC20 = await multicallV3<any>(chainId, ERC20_ABI, tokenCall)
  const ethToken = new Token(chainId, presaleState[0].EthCurrency, restERC20[0][0], restERC20[1][0], restERC20[2][0])

  const restState: PresaleState = {
    End: presaleState[0].End,
    Liquidity: presaleState[0].Liquidity,
    WETH: presaleState[0].WETH,
    EthCurrency: presaleState[0].EthCurrency,
    Currency: presaleState[0].Currency,
    personalMaxAmount: new TokenAmount(ethToken, presaleState[0].personalMaxAmount).raw.toString(),
    personalMinAmount: new TokenAmount(ethToken, presaleState[0].personalMinAmount).raw.toString(),
    presalePrice: Number(presaleState[0].presalePrice.toString()),
    liquidityPrice: Number(presaleState[0].liquidityPrice.toString()),
    liquidityRatio: Number(presaleState[0].liquidityRatio.toString()),
    endTime: Number(presaleState[0].endTime.toString()),
    startTime: Number(presaleState[0].startTime.toString()),
    stakeAmount: new TokenAmount(ethToken, presaleState[0].stakeAmount).raw.toString(),
    oneStakeAmount: new TokenAmount(ethToken, presaleState[0].oneStakeAmount).raw.toString(),
    lockLpOwner: presaleState[0].lockLpOwner,
    lpAddress: presaleState[0].lpAddress,

    lockLpTime: Number(presaleState[0].lockLpTime.toString()),
    onePresale: {
      upperLimit: new TokenAmount(ethToken, cpresale1.upperLimit).raw.toString(),
      userMaxAmount: new TokenAmount(ethToken, cpresale1.userMaxAmount).raw.toString(),
      userMinAmount: new TokenAmount(ethToken, cpresale1.userMinAmount).raw.toString(),
      isWhite: cpresale1.isWhite,
    },
    towPresale: {
      upperLimit: new TokenAmount(ethToken, cpresale2.upperLimit).raw.toString(),
      userMaxAmount: new TokenAmount(ethToken, cpresale2.userMaxAmount).raw.toString(),
      userMinAmount: new TokenAmount(ethToken, cpresale2.userMinAmount).raw.toString(),
      isWhite: cpresale2.isWhite,
    },
  }
  console.log(restState)
  return restState
}

export const fetchPresaleUserState = async (
  chainId: number,
  presaleAddress: string,
  account?: string
): Promise<any> => {
  const presaleCall = [
    { address: presaleAddress, name: 'userInfo', params: [account] },
    { address: presaleAddress, name: 'oneWhiteList', params: [account] },
    { address: presaleAddress, name: 'twoWhiteList', params: [account] },
    { address: presaleAddress, name: 'EthCurrency', params: [] },
    { address: presaleAddress, name: 'getPending', params: [account, false] },
  ]
  const restCreamPresale = await multicallV3<any>(chainId, creamPresaleAbi, presaleCall)
  const [presaleState, cpresale1, cpresale2, EthCurrency, info] = restCreamPresale

  const tokenCall = [
    { address: EthCurrency[0], name: 'decimals', params: [] },
    { address: EthCurrency[0], name: 'symbol', params: [] },
    { address: EthCurrency[0], name: 'name', params: [] },
  ]
  const restERC20 = await multicallV3<any>(chainId, ERC20_ABI, tokenCall)

  const ethToken = new Token(chainId, EthCurrency[0], restERC20[0][0], restERC20[1][0], restERC20[2][0])
  const userInfo: UserInfo = {
    amount: new TokenAmount(ethToken, presaleState.amount.toString()).raw.toString(),
    oneWhite: cpresale1[0],
    twoWhite: cpresale2[0],
    isDraw: presaleState.isDraw,
    fallbackAmount: info[0].toString(),
    receivedAmount: info[2].toString(),
  }
  return userInfo
}

interface Presale {
  _address: string
  _time: number
}

export const fetchCreamPresaleListState = async (chainId: number) => {
  const presaleListCall = [{ address: getCreamPresaleListAddress(chainId), name: 'getPresaleList', params: [0, 100] }]
  console.log(presaleListCall)
  const restCreamPresale = await multicallV3<any>(chainId, creamPresaleListAbi, presaleListCall)
  console.log(restCreamPresale[0][0])
  const presaleList: Presale[] = orderBy(
    restCreamPresale[0][0].map(({ _address, _time }) => {
      return { _address, _time: Number(_time.toString()) }
    }),
    ['_time'],
    ['desc']
  )
  const presaleCall = presaleList.map(({ _address }) => ({ address: _address, name: 'restState', params: [] }))
  const restCreamPresaleList = await multicallV3<any>(chainId, creamPresaleAbi, presaleCall)
  console.log(restCreamPresaleList)

  const restStateList: PresaleState[] = restCreamPresaleList.map((presaleState, index) => {
    return {
      address: presaleList[index]._address,
      End: presaleState[0].End,
      Liquidity: presaleState[0].Liquidity,
      WETH: presaleState[0].WETH,
      EthCurrency: presaleState[0].EthCurrency,
      Currency: presaleState[0].Currency,
      personalMaxAmount: presaleState[0].personalMaxAmount.toString(),
      personalMinAmount: presaleState[0].personalMinAmount.toString(),
      presalePrice: Number(presaleState[0].presalePrice.toString()),
      liquidityPrice: Number(presaleState[0].liquidityPrice.toString()),
      liquidityRatio: Number(presaleState[0].liquidityRatio.toString()),
      endTime: Number(presaleState[0].endTime.toString()),
      startTime: Number(presaleState[0].startTime.toString()),
      stakeAmount: presaleState[0].stakeAmount.toString(),
      oneStakeAmount: presaleState[0].oneStakeAmount.toString(),
      lockLpOwner: presaleState[0].lockLpOwner,
      lpAddress: presaleState[0].lpAddress,
      lockLpTime: Number(presaleState[0].lockLpTime.toString()),
    }
  })
  return restStateList
}

export default {}
