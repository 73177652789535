import {
  TokenAmount,
  Pair,
  Currency,
  Token,
  FACTORY_ADDRESS,
  INIT_CODE_HASH,
  FACTORY_ADDRESS_MAP,
  INIT_CODE_HASH_MAP,
} from 'pancake-sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { POSITION_LIST_MAP } from 'pancake-sdk/constants'
import { flatMap } from 'lodash'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

interface PolyPair {
  factory?: string
  hash?: string
  tokens?: Token[]
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][],
  liquidity?: boolean
): [number, [PairState, Pair | null][]] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies]
  )

  const tokenList: PolyPair[] = useMemo(() => {
    if (liquidity) {
      return tokens.map((token) => {
        return { factory: FACTORY_ADDRESS_MAP[chainId], hash: INIT_CODE_HASH_MAP[chainId], tokens: token }
      })
    }
    const POSITION_LIST: { hash: string; factory: string }[] = POSITION_LIST_MAP[chainId]
    return flatMap(POSITION_LIST, (position): any[] =>
      tokens.map((token) => {
        return {
          hash: position.hash,
          factory: position.factory,
          tokens: token,
        }
      })
    )
    // return flatMap(tokens, (token): any[] =>
    //   POSITION_LIST.map((position) => {
    //     return {
    //       hash: position.hash,
    //       factory: position.factory,
    //       tokens: token,
    //     }
    //   })
    // )
  }, [tokens, chainId, liquidity])

  // const pairAddresses = useMemo(
  //   () =>
  //     tokens.map(([tokenA, tokenB]) => {
  //       return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
  //     }),
  //   [tokens]
  // )

  const pairAddresses = useMemo(
    () =>
      tokenList.map(({ hash, factory, tokens: [tokenA, tokenB] }) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB)
            ? Pair.getHashAddress(factory, hash, tokenA, tokenB)
            : undefined
        } catch (error: any) {
          console.log(error)
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`
          )

          return undefined
        }
      }), // .reduce((acc, cur) => acc.concat(cur), [])
    [tokenList]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return [
      tokens.length,
      results.map((result, i) => {
        const { result: reserves, loading } = result
        const tokenA = tokenList[i].tokens[0]
        const tokenB = tokenList[i].tokens[1]

        if (loading) return [PairState.LOADING, null]
        if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
        if (!reserves) return [PairState.NOT_EXISTS, null]
        const { reserve0, reserve1 } = reserves
        const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
        return [
          PairState.EXISTS,
          new Pair(
            new TokenAmount(token0, reserve0.toString()),
            new TokenAmount(token1, reserve1.toString()),
            tokenList[i].factory
          ),
        ]
      }),
    ]
  }, [results, tokenList, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const pairCurrencies = useMemo<[Currency, Currency][]>(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return usePairs(pairCurrencies, true)[1][0]
}
